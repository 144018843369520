import React from "react";
import { FaRegImages, FaRegImage } from "react-icons/fa";
import styled from "styled-components";
import tw from "twin.macro";

type FileUploadLabelProps = {
  multiple?: boolean;
  label?: string;
  dataCi?: string;
};

const FileUploadLabel: React.FC<FileUploadLabelProps> = ({
  multiple,
  label,
  dataCi
}: FileUploadLabelProps) => {
  return (
    <Container data-ci={dataCi} key={Math.random()}>
      {multiple ? <FaRegImages key={Math.random()}/> : <FaRegImage key={Math.random()}/>}
      {label && <p key={Math.random()}>{label}</p>}
    </Container>
  );
};

const Container = styled.span`
  ${tw`flex flex-col justify-center items-center space-y-2 text-primary-4`}
  svg {
    ${tw`h-8 w-8 fill-current`}
  }
  p {
    ${tw`text-xs text-center uppercase w-24`}
  }
`;
export default FileUploadLabel;
