/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useEffect } from "react"
import { Editor, EditorState, RichUtils } from "draft-js"
import { convertToHTML, convertFromHTML } from 'draft-convert';
import styled from "styled-components";
import tw from "twin.macro";
import { FaHeading, FaBold, FaItalic, FaQuoteLeft, FaRedo, FaUndo} from "react-icons/fa";
import { useRecoilState } from "recoil";
import { obituaryAtom } from "../../../state/atoms";

const BioEditor = ({label}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [obituary, setObituary] = useRecoilState(obituaryAtom)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if(!loaded){
      setLoaded(true)
      setEditorState(EditorState.push(editorState, convertFromHTML(obituary.bio)))
    }
  }, [obituary])

  const onUndoClick = () => {
    setEditorState(EditorState.undo(editorState))
  }
  const onRedoClick = () => {
    setEditorState(EditorState.redo(editorState))
  }
  

  const handleKeyCommand = useCallback((command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    console.log(convertToHTML(editorState.getCurrentContent()))
    if(convertToHTML(editorState.getCurrentContent()) !== '<p></p>'){
      setEditorState(newState)
      setObituary((oldState) => {
        return {
          ...oldState,
          bio: convertToHTML(editorState.getCurrentContent())
        }
      }
    )}
    if (newState) {
      return "handled"
    }
    return "not-handled"
  })

  const onStyleClick = useCallback((style) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, style))
  })
  const onBlockClick = useCallback((block) => {
    setEditorState(RichUtils.toggleBlockType(editorState, block))
  })


  const onChangeHandler = (editorState) => {
    setObituary((oldState) => {
      return {
        ...oldState,
        bio: convertToHTML(editorState.getCurrentContent())
      }})
    setEditorState(editorState)
  }

  return (
    <BioArea>
      <CustomLabel hide={false}>{label}</CustomLabel>
      <ButtonsArea>
          <CustomButton type="button" onClick={() => onStyleClick("BOLD")}>
            <FaBold size={16} />
          </CustomButton>
          <CustomButton type="button" onClick={() => onStyleClick("ITALIC")}>
            <FaItalic size={16} />
          </CustomButton>
          <CustomButton type="button" onClick={() => onBlockClick("header-one")}>
            <FaHeading size={16} />
          </CustomButton>
          <CustomButton type="button" onClick={() => onBlockClick("header-two")}>
            <FaHeading size={12} />
          </CustomButton>
          <CustomButton type="button" onClick={() => onBlockClick("blockquote")}>
            <FaQuoteLeft size={12} />
          </CustomButton>
          <div className="controlButtons">
          <CustomButton type="button" onClick={() => onUndoClick()}>
            <FaUndo size={12} />
          </CustomButton>
          <CustomButton type="button" onClick={() => onRedoClick()}>
            <FaRedo size={12} />
          </CustomButton>
          </div>
        </ButtonsArea>
        <EditorArea>
      <Editor 
        editorState={editorState} 
        handleKeyCommand={handleKeyCommand} 
        onChange={onChangeHandler} 
        // handleReturn={handleReturn}
        stripPastedStyles={true}
        />
        </EditorArea>
    </BioArea>
  )
}

export default BioEditor

const BioArea = styled.div`
  ${tw`flex flex-col h-full w-full justify-start items-center space-y-2`}
`

const ButtonsArea = styled.div`
  ${tw`flex flex-row space-x-3 items-start w-full rounded-md p-3 bg-white shadow-lg bg-opacity-50`}
  .controlButtons{
    ${tw`flex flex-row justify-end flex-grow space-x-2`}
  }
`

const CustomLabel = styled.label`
  ${tw`relative font-sans transition-all duration-300 ease-in-out pointer-events-none text-primary-4 text-sm tracking-normal w-full text-left` }
`;

const CustomButton = styled.button`
  ${tw`font-bold w-8 h-8 flex justify-center items-center rounded bg-primary-2 border-primary-2 border-solid border-primary-3 shadow-lg cursor-pointer hover:bg-primary-3 transition-all duration-300 ease-in-out text-black`}
  svg{
    ${tw`opacity-50`}
  }
`

const EditorArea = styled.div`
  ${tw` w-full px-1 min-h-[12rem] h-full overflow-y-auto text-center cursor-text rounded-md p-3 bg-white bg-opacity-50 shadow-lg  `}
  div{
    ${tw`w-full flex flex-col text-center mx-auto `}
  }
  h1{
    ${tw`font-serif text-4xl mb-2`}
  }
  h2{
    ${tw`font-serif text-3xl mb-2`}
  }
  p{
    ${tw`mb-2`}
  }

  blockquote{
    ${tw`text-lg tracking-wider flex flex-row font-light text-black-80 italic bg-white bg-opacity-50`}
  }

  blockquote:first-of-type{
    ${tw`mt-3`}
    div span span::before{
      content: "“";
      ${tw`text-xl text-primary-4 font-bold font-serif mr-1`}
    }
  }

  blockquote:last-of-type{
    ${tw`mb-3`}
    div span span::after{
      content: "„";
      ${tw`text-xl text-primary-4 font-bold font-serif ml-1`}
    }
  }
  
`