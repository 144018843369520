/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Faqs from '../../components/organisms/landing/Faqs'
import Footer from '../../components/organisms/common/Footer'
import Header from '../../components/organisms/landing/Header'
import Navbar from '../../components/organisms/landing/Navbar'
import Obituaries from '../../components/organisms/landing/Obituaries'
import Plannings from '../../components/organisms/landing/Plannings'

type Props = {}

const Landing = (props: Props) => {

  let history = useNavigate();
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    document.title = 'Mein Benu';
  }, )

  useEffect(() => {
    if(isAuthenticated){
      history("/dashboard");
    }
  }, [isAuthenticated])
  return (
    <>
      <Navbar />
      <Header />
      <Obituaries />
      <Plannings />
      <Faqs />
      <Footer/>
    </>
  )
}

export default Landing