export const enum SizeProps {
  SMALL = "small",
  MEDIUM = "medium",
  BIG = "big",
}

export const enum ColorProps {
  WHITE = "white",
  PRIMARY = "primary",
  PRIMARY_1 = "primary-1",
  PRIMARY_2 = "primary-2",
  PRIMARY_3 = "primary-3",
  PRIMARY_4 = "primary-4",
  SECONDARY = "secondary",
}

export const enum VisualProps {
  SOLID = "solid",
  OUTLINE = "outline",
  UNDERLINE = "underline",
}

export const enum IconProps {
  PHONE = "phone",
}

export type TObituaryTribute = {
  id?: string;
  name: string;
  tributeType: IObituaryTributeType;
  parentId: string;
};

export type IObituaryTributeType =
| "STAR"
| "BALLOON"
| "DOVE"
| "CROWN"
| "FLOWER"
| "CANDLE"

export type TObituaryTemplate = 
  | "default"

export type TObituaryImage = {
  id?: string;
  url: string;
  width?: number;
  height?: number;
};

export interface IObituaryItem {
  id?: string;
  userId?: string;
  slug?: string | null;
  template: TObituaryTemplate;
  name?: string | null;
  photo?: TObituaryImage | null;
  birthDate?: string | null;
  deathDate?: string | null;
  location?: string | null;
  bio?: string | null;
  receptionLocation?: TObituaryLocationProps;
  serviceLocation?: TObituaryLocationProps;
  gallery: TObituaryImage[];
  enableTributes?: boolean;
  enableComments?: boolean;
  enableReplies?: boolean;
  published: boolean;
  googleIndex?: boolean;
  modalService: boolean;
  modalReception: boolean;
  tributes: TObituaryTribute[];
  comments: TObituaryComment[];
}


export interface IUser{
  email: string;
  email_verified: boolean;
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;
  session_token?: string;
}

export type TObituaryLocationProps = {
  type?: TObituaryLocationTypes;
  date?: Date | string;
  time?: Date | string;
  mainInfo?: string;
  alternativeInfo?: string;
  location?: TObituaryLocation;
};

export type TObituaryLocationTypes =
  | 'service'
  | 'reception'

export type TObituaryLocation = {
  // Google Maps API Props
  route?: string;
  street_number?: string;
  locality?: string;
  postal_code?: string;
  lat?: number;
  lng?: number;
  name?: string
};


export type TObituaryComment = {
  id: string;
  obituaryId: string;
  name: string;
  image: TObituaryImage | null
  message: string;
  timestamp?: string;
  uuid: string;
  replies?: TObituaryReply[]
}

export type TObituaryReply = {
  id: string;
  commentUuid: string;
  name: string;
  message: string;
  timestamp?: string;
  published: boolean;
}

export type TFuneralType = 
| "BURIAL"
| "CREMATION"
| "TREE_FUNERAL"
| "SEA_FUNERAL"
| "HOME_URN"

export type TFuneralPlanningReason =
| "ACUTE_BEREAVEMENT"
| "IMPENDING_BEREAVEMENT"
| "PROVISION"

export type TPlanning = {
  id: string;
  userId: string;
  angebot: string;
  funeralType: TFuneralType;
  planningReason: TFuneralPlanningReason;
  location: string,
  status: string,
  cost: string,
  createdAt: string,
  }