import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import tw from "twin.macro";
import axiosApi from "../../api/axiosApi";
import ButtonComponent from "../../components/atoms/common/ButtonComponent";
import Layout from "../../components/layout/Layout";
import { ColorProps, SizeProps, VisualProps } from "../../resources";
import { userAtom } from "../../state/atoms";
import { toast } from "react-toastify";

type Props = {};

const ConfirmEmail = (props: Props) => {
  const user_info = useRecoilValue(userAtom)
  let history = useNavigate();

useEffect(() => {
  if(user_info.session_token){
    loadDashboard()
  }
  // eslint-disable-next-line
}, [user_info])

const loadDashboard = () => {
  if(user_info.email_verified){
    history("/dashboard")
  }
}

const resendLink = async () =>{
  console.log("Resend Email")
  await axiosApi.get("/resend-verification", {})
        .then(res => {
          toast.success("E-Mail wurde verschickt")
        }
        ).catch(error => {
          toast.error("Error")
        })
}

  return (
    <Layout>
      <InfoContainer>
        <InfoArea>
        <img src="https://res.cloudinary.com/dkyl4jbxx/image/upload/v1662038592/mein/assets/email_confirmation.png" alt="email confirmation"/>
        <div>
          <h2>
          Vielen Dank für Ihre Anmeldung auf <br/><span>Mein Benu.</span>
          </h2>
          <p>
          Um Ihre Anmeldung abzuschließen, klicken Sie bitte auf den Link in der E-Mail, die wir Ihnen gerade gesendet haben.
          </p>
          <p>
          Falls Sie keine E-Mail bekommen haben, <span onClick={()=>{resendLink()}}>klicken Sie bitte hier für eine erneute Bestätigungsmail.</span>        
          </p>
          <ButtonComponent
            visual={VisualProps.SOLID}
            color={ColorProps.PRIMARY}
            size={SizeProps.MEDIUM}
            className="mx-auto"
            onClick={() => {
              window.location.reload()
            }}
          >
            habe ich schon bestätigt
          </ButtonComponent>
        </div>
        </InfoArea>
      </InfoContainer>
    </Layout>
  );
};

const InfoContainer = styled.div`
  ${tw`w-full xl:container flex flex-grow flex-col p-3 space-y-2`}
`;
const InfoArea = styled.div`
  ${tw`w-full 2xl:w-4/6 mx-auto flex flex-col lg:flex-row mx-auto space-y-8 lg:space-y-0 lg:space-x-10 justify-center items-center text-center lg:text-left my-2 md:my-4 lg:my-12 xl:my-24`}
  img{
    ${tw`w-2/3 md:w-60 lg:w-80`}
  }
  div{
    ${tw`space-y-4 lg:space-y-8 flex flex-grow flex-col`}

    h2{
      ${tw`font-serif text-4xl leading-10 `}
      span{
        ${tw`font-serif text-primary-4`}
      }
    }
    p {
      ${tw`text-lg font-sans tracking-wider`}
      span{
        ${tw`font-bold underline cursor-pointer`}
      }
    }
    button{
      ${tw`mx-auto`}
    }
  }
`

export default ConfirmEmail;
