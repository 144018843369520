import { t } from 'i18next'
import React from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import tw from 'twin.macro'
import { getTributeIcon, resizeImage } from '../../../lib/images'
import { ColorProps, IObituaryTributeType, SizeProps, TObituaryTribute } from '../../../resources'
import { obituaryAtom, tributesModalAtom } from '../../../state/atoms'
import ModalContainer from '../../atoms/common/ModalContainer'
import NotificationMarker from '../../atoms/common/NotificationMarker'

type Props = {}

const TributesList = (props: Props) => {
  const obituary = useRecoilValue(obituaryAtom)
  const [tributesModal, setTributesModal] = useRecoilState(tributesModalAtom)
  const closeModal = () => {
    setTributesModal({
      open: false,
      type: ""
  })}

  if(tributesModal.open){
    return <ModalContainer closeModal={()=>closeModal()}>
      <Container>
        <ModalHeaderArea>
          {t(`tributes.tributeType${tributesModal.type}`)}
            <button type="button" onClick={() => closeModal()}>
              <AiOutlineClose />
            </button>
          </ModalHeaderArea>
          <div>
          <GridContainer>
            <RenderGroup type="STAR" key="STAR" actual={tributesModal.type as IObituaryTributeType}  />
            <RenderGroup type="BALLOON" key="BALLOON" actual={tributesModal.type as IObituaryTributeType}/>
            <RenderGroup type="DOVE" key="DOVE" actual={tributesModal.type as IObituaryTributeType}/>
            <RenderGroup type="CROWN" key="CROWN" actual={tributesModal.type as IObituaryTributeType}/>
            <RenderGroup type="FLOWER" key="FLOWER" actual={tributesModal.type as IObituaryTributeType}/>
            <RenderGroup type="CANDLE" key="CANDLE" actual={tributesModal.type as IObituaryTributeType}/>
          </GridContainer>  
          </div>
          <div>
            { obituary.tributes.filter((tribute:TObituaryTribute) => tribute.tributeType === tributesModal.type).map((item, index) => <p>{item.name}</p>)}
          </div>
        </Container>    
    </ModalContainer> 
  }else{
    return <></>
  }
}



const RenderGroup = (props:any) =>{
  let type:IObituaryTributeType = props.type
  const setTribute = useSetRecoilState(tributesModalAtom)
  const obituary = useRecoilValue(obituaryAtom)
  const openList = () => {
    setTribute({
      open: true,
      type: props.type})
  }
  let items = obituary.tributes.filter((tribute:TObituaryTribute) => tribute.tributeType === type)
  if(items.length > 0) {
    return <GridItem onClick={()=>{
      openList()
    }}>
      <img src={resizeImage(getTributeIcon(type),120,120)} className={props.actual === props.type ? 'h-32 w-32' : 'h-24 w-24'} alt={type}/>
      <NotificationMarker number={items.length} visual={ColorProps.PRIMARY} size={SizeProps.MEDIUM} />

    </GridItem>
  }
  return <GridItem>
  <img src={resizeImage(getTributeIcon(type),120,120)} className=" h-32 w-32" alt={type}/>

  <NotificationMarker number={0} visual={ColorProps.SECONDARY} size={SizeProps.MEDIUM} />

</GridItem>
}


const GridContainer = styled.div`
  ${tw`flex flex-row gap-2 md:gap-4 lg:gap-8 pt-4 items-center justify-center`}
`

const GridItem = styled.div`
  ${tw`relative flex flex-row `}
  
  img{
    ${tw`h-12 w-12 md:w-16 md:h-16 relative`}
  }
`

const Container = styled.div`
  ${tw`bg-gradient-to-b from-primary-2  via-white to-white w-full flex flex-col items-center justify-start w-[85vw] md:w-auto h-[16rem] p-4 space-y-4 rounded-lg`}
`;


const ModalHeaderArea = styled.div`
  ${tw`flex flex-row justify-between items-center font-serif text-2xl pt-2 px-2 md:px-4 w-full`}
  button {
    ${tw`bg-transparent border-0 outline-none text-secondary cursor-pointer transition-all hover:text-secondary-dark h-8 w-8 flex flex-row justify-end items-center`}
    svg {
      ${tw`w-6 h-6`}
    }
  }
`;

export default TributesList