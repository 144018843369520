import React from 'react'
import { MdKeyboardArrowRight } from 'react-icons/md'
import styled from 'styled-components'
import tw from 'twin.macro'

type Props = {
  question: string
  answer: string
  position: number
  actualPosition: number
  setPosition: (position: number) => void
}

const FaqItem = ({question, answer, position, actualPosition, setPosition}: Props) => {
  return (
    <FaqItemContainer>
      <FaqQuestion onClick={()=>setPosition(position)}>
        <span className={`icon ${position === actualPosition && 'rotate'}`}>
            <MdKeyboardArrowRight size={20} />
          </span>
        {question}
      </FaqQuestion>
      {position === actualPosition && <FaqAnswer>{answer}</FaqAnswer>}
    </FaqItemContainer>
  )
}

const FaqItemContainer = styled.div`
  ${tw`flex flex-col border-solid border-0 border-b-2 border-orange border-opacity-10 p-4 cursor-pointer`}
  &:last-child{
    ${tw`border-b-0`}
  }
`

const FaqQuestion = styled.div`
  ${tw`text-xl font-serif flex flex-row items-center`}
  .icon{
    ${tw`w-6 h-6 mr-2 text-orange `}
    svg{
      ${tw`transition-all duration-200`}
    }
  }
  .rotate{
    svg{
    transform: rotate(90deg);
    }
  }
`
const FaqAnswer = styled.div`
  ${tw`text-base px-4 pt-2 ml-4`}
` 

export default FaqItem