import React, { useEffect, useState } from "react";
import { FieldProps } from "formik";
import styled from "styled-components";
import tw from "twin.macro";

type CustomTextAreaComponentProps = {
  type?: string;
  label?: string;
};

const CustomTextAreaComponent: React.FC<
  CustomTextAreaComponentProps & FieldProps
> = ({ field, form: { touched, errors }, type = "text", label, ...props }) => {
  const [hideLabel, setHideLabel] = useState(field.value > 0 ? true : false);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (touched[field.name] && errors[field.name]) {
      setError(true);
    } else {
      setError(false);
    }
  }, [errors, field.name, touched]);
  useEffect(() => {
    if (field.value) {
      setHideLabel(true);
    }
  }, [field]);
  return (
    <CustomTextAreaArea error={error}>
      <CustomTextArea
        // type={type}
        {...field}
        {...props}
        onFocus={(e) => {
          setHideLabel(true);
        }}
        onBlur={(e) => {
          e.target.value.length === 0
            ? setHideLabel(false)
            : setHideLabel(true);
        }}
        placeholder=""
        autoComplete="off"
        hide={hideLabel}
      />
      <CustomLabel hide={hideLabel}>{label}</CustomLabel>
      {error && <ErrorArea>{errors[field.name]}</ErrorArea>}
    </CustomTextAreaArea>
  );
};

type LabelProps = {
  error?: boolean;
  hide?: boolean;
};

const CustomTextAreaArea = styled.div<LabelProps>`
  ${tw`flex flex-col w-full relative bg-white border border-solid border-primary-3 h-full flex-grow rounded-lg`}
  ${(props) => (props.error ? tw`border-secondary` : tw`border-primary-3`)}
`;
const CustomTextArea = styled.textarea<LabelProps>`
  ${tw`bg-white h-full pt-5 px-3 outline-none  rounded-md font-sans tracking-wide relative border-0 text-base resize-none`}
  ${(props) => (props.error ? tw`border-secondary` : tw`border-primary-3`)}
`;
const CustomLabel = styled.label<LabelProps>`
  ${tw`absolute font-sans left-3 transition-all duration-300 ease-in-out pointer-events-none leading-5`}
  ${(props) =>
    props.hide
      ? tw`top-0.5 text-primary-4 text-sm tracking-normal`
      : tw`top-4 text-black-40 text-base tracking-wide`}
`;
const ErrorArea = styled.div`
  ${tw`text-xs absolute top-0 right-0 bg-secondary/70 text-white px-1 rounded-tr rounded-bl`}
`;

export default CustomTextAreaComponent;
