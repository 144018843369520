/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import "./i18n/config";
import { RouterComponent } from "./routes/RouterComponent";
// import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { GlobalStyle } from "./styles/global";



const App: React.FC = () => {

  return (
    <>
      <RouterComponent />
      <GlobalStyle />
      <ToastContainer />
    </>
  );
};
export default App;


