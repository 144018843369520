import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { SizeProps, ColorProps } from "../../../resources";

export interface NotificationMarkerProps {
  number: number;
  visual: ColorProps;
  size?: SizeProps;
}

export const enum NotificationStyle {
  PRIMARY = "primary",
  SECONDARY = "secondary",
}

const NotificationMarker: React.FC<NotificationMarkerProps> = (
  props: NotificationMarkerProps
) => {
  return (
    <NotificationMarkerContainer {...props}>
      {props.number}
    </NotificationMarkerContainer>
  );
};

const NotificationMarkerContainer = styled.div<NotificationMarkerProps>`
  ${(props) =>
    props.visual === ColorProps.PRIMARY && tw`bg-secondary text-white  `}
  ${(props) =>
    props.visual === ColorProps.SECONDARY && tw`bg-primary-4 text-white`}
  ${(props) =>
    props.size === SizeProps.SMALL &&
    tw`text-xs px-1.5 py-1 rounded-xl top-1 right-1`}
  ${(props) =>
    props.size === SizeProps.MEDIUM &&
    tw`text-sm px-2.5 py-1.5 rounded-2xl top-0 right-0`}
  ${(props) =>
    props.size === SizeProps.BIG &&
    tw`text-base px-3 py-2 rounded-2xl top-0 right-0`}
  ${tw`font-bold leading-3 absolute shadow-sm border border-solid border-black/20`}
`;

export default NotificationMarker;
