import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import { formatDateToString } from "../../../lib/dates";
import {  IObituaryItem } from "../../../resources";
import { AiOutlineUser} from "react-icons/ai";
// import ButtonComponent from "../../atoms/common/ButtonComponent";
// import { useTranslation } from "react-i18next";
// import NotificationMarker from "../../atoms/common/NotificationMarker";


const AdminObituaryItem: React.FC<IObituaryItem> = (
  props: IObituaryItem
) => {
  const navigate = useNavigate()
  return (
    <Container
      onClick={() => {navigate(`/obituary/${props.id}`)}}
    >
    <div className="header">
        <div className="icon">
        {props?.photo ? 
          <img src={props.photo.url} alt={props?.name ? props.name : ""} /> : <AiOutlineUser />
        }
        </div>
        <div className="info">
          <p className="title">{props.slug}</p>
          {/* t("") */}
          <p className="name">{props.name}</p>
        </div>
      </div>
      <div className="content">
        <div className="item">
          {/* <p className="label">{t('dashboard.obituaries.itemBirthdate')}</p> */}
          <p className="info">{props?.birthDate ? formatDateToString(props.birthDate) : "null"}</p>
        </div>
        <div className="item">
          {/* <p className="label">{t('dashboard.obituaries.itemDeathdate')}</p> */}
          <p className="info">{props?.deathDate ? formatDateToString(props.deathDate) : "null"}</p>
          
        </div>
        <div className="item">
          {/* <p className="label">{t('dashboard.obituaries.itemLocation')}</p> */}
          <p className="info"> {props?.location && <span className="location">{props.location}</span>}</p>
        </div>
        {/* <div className="item-double">
          <ButtonComponent
            visual={VisualProps.SOLID}
            color={ColorProps.PRIMARY}
            size={SizeProps.SMALL}
            onClick={() => {navigate(`/obituary/${props.id}`)}}
            data-ci={`edit-${props.slug}`}
            
          >
            <AiFillEdit size={12}/>
            {t('dashboard.obituaries.itemEdit')}</ButtonComponent>
          <ButtonComponent
            visual={VisualProps.SOLID}
            color={ColorProps.SECONDARY}
            size={SizeProps.SMALL}
            onClick={() => {window.open(`${process.env.REACT_APP_MAIN_SITE}/traueranzeigen/${props.slug}`, "_blank")}}
            data-ci={`view-${props.slug}`}
          ><AiOutlineLink/>
          {t('dashboard.obituaries.itemView')}</ButtonComponent>
        </div> */}
      </div>
    </Container>

  );
};

const Container = styled.div.attrs({ className: "group" })`
  ${tw`bg-white  border-0 flex flex-col justify-center items-start border-solid border-primary-2 transition-all duration-300 ease-in-out hover:bg-primary-1 hover:shadow-lg space-y-0 text-center cursor-pointer`}
  .header {
    background-image: url('https://res.cloudinary.com/dkyl4jbxx/image/upload/v1656963200/mein/assets/bgObituaryItem.png?bla');
    ${tw`w-full flex flex-row pt-3 justify-start items-center bg-left bg-no-repeat bg-contain pl-2 space-x-2`}
    .icon{
      ${tw`h-16 w-16 bg-white rounded-full mr-1 shadow-lg`}
      img, svg{
        ${tw`h-16 w-16 object-cover rounded-full`}
      }
      svg{
        ${tw`opacity-20`}
      }
    }
    .info {
      ${tw`text-left flex flex-col space-y-0.5 pl-1`}
      .title {
        ${tw`text-xs uppercase text-black/60 tracking-wider`}
      }
      .name {
        ${tw`font-serif text-xl`}
      }
    }
  }
  .content {
    ${tw`p-4 pt-2 grid grid-cols-2 gap-2 w-full`}
    .item {
      ${tw`flex flex-col text-left`}
      .label {
        ${tw`text-xs uppercase tracking-wider leading-3 text-black/60`}
      }
      .info {
        ${tw`text-sm font-medium leading-5 tracking-wide`}
      }
    }
    .item-double {
      ${tw`flex flex-row justify-end space-x-2 text-left col-span-2 items-end`}
      .info {
        ${tw`text-base font-medium leading-5 tracking-wide`}
      }
    }
  }
`;

export default AdminObituaryItem;
