/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */

import styled from "styled-components";
import tw from "twin.macro";
import Header from "../organisms/common/Header";
// import Sidebar from "../organisms/common/Sidebar";
import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axiosApi from "../../api/axiosApi";
import { useRecoilState } from "recoil";
import { userAtom } from "../../state/atoms";
import Footer from "../organisms/common/Footer";
type LayoutProps = {};

const Layout: React.FC<LayoutProps> = (props) => {
  const [user_info, setUserInfo] = useRecoilState<any>(userAtom);
  const auth0 = useAuth0()
  useEffect(() => {
    async function storeUserInfo () {
      if(auth0.isAuthenticated){
        axiosApi.defaults.headers.common['Authorization'] = `Bearer ${await auth0.getAccessTokenSilently()}`;
        setUserInfo({...auth0.user, session_token:await auth0.getAccessTokenSilently()})
      }
    }
    storeUserInfo();
  }, [auth0]);

    return(
      <>
        {
          user_info?.sub ? 
          <MasterContainer>
            <Header />
            <ContentContainer>
              {/* <Sidebar /> */}
              <ContentArea>{props.children}</ContentArea>
            </ContentContainer>
            <Footer/>
          </MasterContainer>
        :<></>
        }
      </>

    )
};

const MasterContainer = styled.div`
  ${tw`h-full flex flex-col min-h-screen flex-grow`}
`

const ContentContainer = styled.div`
  ${tw`xl:container relative mx-auto flex flex-row flex-grow space-x-8 mt-8 px-4 mb-4 md:mb-16`}
`;
const ContentArea = styled.div`
  ${tw`xl:flex relative flex-col flex-grow space-y-4`}
`;

export default Layout;
