import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import {
  ColorProps,
  IconProps,
  SizeProps,
  VisualProps,
} from "../../../resources";

type ButtonComponentProps = {
  dataCi?: string;
  visual?: VisualProps;
  size?: SizeProps;
  color?: ColorProps;
  icon?: IconProps;
  widthFull?: boolean;
  center?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isSubmit?: boolean;
  className?: string;
};

const ButtonComponent: React.FC<ButtonComponentProps> = (props) => {
  return (
    <ButtonContainer type={props.isSubmit ? "submit" : "button"} data-ci={props.dataCi ? props.dataCi : null} {...props}>
      {props.children}
    </ButtonContainer>
  );
};

const ButtonContainer = styled.button<ButtonComponentProps>`
  // Default parameters
  ${tw`flex flex-row justify-center items-center border-2 border-solid transition-all ease-in-out duration-300 cursor-pointer uppercase font-semibold `}
  // Width and Center
  ${(props) => props.widthFull && tw`w-full`}
  ${(props) => props.center && tw`mx-auto`}
  // Size Parameters
  ${(props) =>
    props.size === SizeProps.SMALL && tw`text-sm py-1 px-1 md:px-1 md:tracking-wide`}
  ${(props) =>
    props.size === SizeProps.MEDIUM && tw`text-base py-1.5  px-2 md:px-6 md:tracking-widest`}
  ${(props) =>
    props.size === SizeProps.BIG && tw`text-lg py-2 px-8 tracking-widest`}
  // Color Parameters
  ${(props) =>
    props.color === ColorProps.PRIMARY &&
    tw`bg-secondary  border-secondary text-secondary hover:(text-secondary-dark bg-secondary-dark border-secondary-dark) `}
  ${(props) =>
    props.color === ColorProps.SECONDARY &&
    tw`bg-primary-4  border-primary-4 text-primary-4 hover:(text-primary-link bg-primary-link border-primary-link)`}
  // Visual Parameters
  ${(props) =>
    props.visual === VisualProps.SOLID &&
    tw`rounded-3xl shadow-none text-white hover:(text-white shadow-md)`}
  ${(props) =>
    props.visual === VisualProps.OUTLINE &&
    tw`rounded-3xl bg-opacity-0 hover:(bg-opacity-10) `}
  ${(props) =>
    props.visual === VisualProps.UNDERLINE &&
    tw`bg-opacity-0 border-0 border-b-2 hover:(bg-opacity-0) `}
  svg{
    ${tw`mr-0.5 md:mr-1`}
  }
`;

export default ButtonComponent;
