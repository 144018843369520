import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import Dropzone, {
  IDropzoneProps,
  ILayoutProps,
} from "react-dropzone-uploader";
import FileUploadLabel from "../../atoms/form/FileUploadLabel";
// import { useAuth0 } from "@auth0/auth0-react";
// import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
// import {  addUserPhoto, removeUserPhoto } from "../../../store/reducers/obituary";
import { resizeImage } from "../../../lib/images";
import { obituaryAtom, userAtom } from "../../../state/atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  useCleanUserPhoto,
  useUserPhoto,
} from "../../../state/hooks/obituary/obituary";
import ProgressAnimation from "../../atoms/common/ProgressAnimation";
import { toast } from "react-toastify";
import { t } from "i18next";

type Props = {};

const Layout = ({ input, dropzoneProps }: ILayoutProps) => {
  return (
    <>
      <div {...dropzoneProps} key="xpto">
        {input}
      </div>
    </>
  );
};

const DropAreaUserImage = (props: Props) => {
  const [obituary] = useRecoilState(obituaryAtom);
  const user_info = useRecoilValue(userAtom);
  const cleanPhoto = useCleanUserPhoto();
  const updatePhoto = useUserPhoto();
  const [isUpload, setIsUpload] = useState(false);
  const onChangeStatus: IDropzoneProps["onChangeStatus"] = (
    fileWithMeta,
    status,
    items
  ) => {
    if (status === "preparing") {
      cleanPhoto();
      setIsUpload(false);
      toast.loading("Uploading...");
    } else if (status === "uploading") {
      setIsUpload(true);
    } else if (status === "done") {
      toast.dismiss();
      toast.success("Upload done!");
      setIsUpload(false);
      let { result } = JSON.parse(fileWithMeta.xhr?.response);
      console.log(result);
      updatePhoto({
        url: result.url,
        width: result.width,
        height: result.height,
      });
    }
  };

  const getUploadParams: IDropzoneProps["getUploadParams"] = (props) => {
    let body = new FormData();
    body.append("profile-file", props.file);
    body.append("folder", `mein/${String(user_info?.sub).split("|")[1]}`);
    return { url: "https://old.benu.at/api/upload/single/", body };
  };

  return (
    <Container key="container-user-image">
      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={onChangeStatus}
        LayoutComponent={Layout}
        multiple={false}
        disabled={isUpload}
        key="Dropzone-user-image"
        inputContent={
          obituary.photo?.url ? (
            <ProfileImage
              src={resizeImage(obituary.photo.url, 180, 180)}
              data-ci="change-user-image"
              key="test2"
            />
          ) : (
            <FileUploadLabel
              data-ci="upload-user-image"
              multiple={false}
              label={t("obituary.form.userDropdownAreaPlaceholder")}
              key="test"
            />
          )
        }
        inputWithFilesContent={
          obituary.photo?.url ? (
            <ProfileImage
              src={resizeImage(obituary.photo.url, 180, 180)}
              key="test2"
            />
          ) : (
            <ProgressArea>
              <ProgressAnimation />
            </ProgressArea>
          )
        }
      />
    </Container>
  );
};

const ProfileImage = styled.img`
  ${tw`h-full w-full  rounded-full`}
`;
const ProgressArea = styled.div`
  ${tw``}
  svg {
    ${tw`h-16 w-16 pt-2`}
  }
`;

const Container = styled.div`
  ${tw`flex flex-col relative bg-white h-44 w-44 mx-auto rounded-full border border-solid border-primary-3 justify-center items-center`}
  .dzu-dropzone {
    ${tw`bg-transparent rounded-md overflow-scroll flex flex-col items-center justify-center w-full relative transition-all ease-in-out duration-150 h-44 p-0 m-0 overflow-hidden `}
  }
  .dzu-dropzoneActive {
    ${tw`border-solid border-secondary bg-primary-2/60 rounded-full opacity-20`}
  }
  .dzu-input {
    display: none;
  }
`;

export default DropAreaUserImage;
