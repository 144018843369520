import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

type Props = {
  closeModal: Function;
};

const ModalContainer: React.FC<Props> = (props) => {
  return (
    <Container
      onClick={(e) => {
        props.closeModal();
      }}
    >
      <Area
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {props.children}
      </Area>
    </Container>
  );
};

const Container = styled.div`
  ${tw`bg-black/50 fixed w-full h-full z-20 !mt-0 top-0 left-0 flex flex-col justify-center items-center`}
  /* -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px); */
`;

const Area = styled.div`
  ${tw`w-auto h-auto bg-white rounded-lg shadow-2xl`}
`;

export default ModalContainer;
