import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

type Props = {
  title: string
  subtitle: string
  image?: string
}

const StepContainer = ({title,subtitle,image}: Props) => {
  return (
    <Container>
      <img src={image} alt={title}/>
      <h4 className='title'>{title}</h4>
      <p className='subtitle'>{subtitle}</p>   
    </Container>

    
  )
}

const Container = styled.div`

  ${tw`flex flex-col space-y-4 w-full px-16`}
  .title{
    ${tw`font-serif text-2xl text-primary-4 text-center tracking-wider`}
  }
  .subtitle{
    ${tw`text-center tracking-wider leading-5`}
  }
  img{
    ${tw`w-3/4 h-auto mx-auto mb-8`}
  }
`

export default StepContainer