import { atom } from "recoil";
import { number } from "yup";
import { IObituaryItem, TObituaryComment, TObituaryReply } from "../resources";

export const obituariesAtom = atom<IObituaryItem[]>({
  key: "obituaries",
  default: [],
})


export const adminObituaries = atom<any>({
  key: "adminObituaries",
  default: {
    entries: [] as IObituaryItem[],
    total: number,
    page: number,
    size: number
  }
})

export const dashboardAtom = atom<any>({
  key: "dashboard",
  default: {
    isLoaded: false,
    obituaries: [] as IObituaryItem[],
    plannings: []
  }
})

export const userAtom = atom<any>({
  key: "user",
  default: {}
})

export const obituaryAtom = atom<IObituaryItem>({
  key: "obituary",
  default: {
    template: "default",
    name: "",
    photo: null,
    birthDate: null,
    deathDate: null,
    location: null,
    bio: null,
    gallery: [],
    serviceLocation: {
      type: "service",
    },
    receptionLocation: {
      type: "reception",
    },
    published: false,
    modalService: false,
    modalReception: false,
    tributes: [],
    comments: [],
  },
})

export const commentModalAtom = atom<any>({
  key: "commentModal",
  default: {
    open: false,
    payload: {} as TObituaryComment
  }
})

export const createObituaryModalAtom = atom<any>({
  key: "createObituaryModal",
  default: {
    open: false
  }
})

export const deleteObituaryModalAtom = atom<any>({
  key: "deleteObituaryModal",
  default: {
    open: false
  }
})

export const replyModalAtom = atom<any>({
  key: "replyModal",
  default: {
    open: false,
    payload: {} as TObituaryReply
  }
})

export const tributesModalAtom = atom<any>({
  key: "tributesModal",
  default: {
    open: false,
    type: ""
  }
})

