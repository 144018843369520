import { IObituaryTributeType } from "../resources";

export const enum imagesUrls {
  OBITUARIES_COMP_HEADER = "https://res.cloudinary.com/dkyl4jbxx/image/upload/v1643814842/mein/assets/obituaries-comp-header-bg.png",
  DEPUTIES_COMP_HEADER = "https://res.cloudinary.com/dkyl4jbxx/image/upload/v1643814842/mein/assets/deputies-comp-header-bg.png",
  DEPUTIES_FOR_COMP_HEADER = "https://res.cloudinary.com/dkyl4jbxx/image/upload/v1643814842/mein/assets/deputies-for-comp-header-bg.png",
  SECTIONS_COMP_HEADER = "https://res.cloudinary.com/dkyl4jbxx/image/upload/v1643814842/mein/assets/sections-comp-header-bg.png",
  PLANNINGS_COMP_HEADER = "https://res.cloudinary.com/dkyl4jbxx/image/upload/v1643814842/mein/assets/plannings-comp-header-bg.png",
}

const enum cloudinaryParams {
  FORMAT_AUTO = "f_auto",
}

export const resizeImage = (url:string, width: number, height: number) =>{
  return optimizeImage(url).replace("/upload", `/upload/w_${width},h_${height},c_fill`)
}

export const optimizeImage = (url: string): string => {
  return url.replace("/upload/", `/upload/${cloudinaryParams.FORMAT_AUTO}/`);
};

export const getTributeIcon = (value:IObituaryTributeType) => {
  let url = "https://res.cloudinary.com/dkyl4jbxx/image/upload/"
  switch (value) {
      case "STAR":
      return `${url}/v1606842207/tribut_type_1_f7909d7130.png`
      case "BALLOON":
      return `${url}/v1606842207/tribut_type_2_7a043266d9.png`
      case "CANDLE":
      return `${url}/v1606842208/tribut_type_3_614027432d.png`
      case "DOVE":
      return `${url}/v1606842207/tribut_type_4_8e79c5ef13.png`
      case "CROWN":
      return `${url}/v1606842207/tribut_type_5_499d426f23.png`
      case "FLOWER":
      return `${url}/v1606842207/tribut_type_6_a43ad57674.png`
  }
}