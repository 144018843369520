import React from 'react'
import { useRecoilValue } from 'recoil'
import styled from 'styled-components'
import tw from 'twin.macro'
import Layout from '../../components/layout/Layout'
import { userAtom } from '../../state/atoms'
// import Header from '../../components/organisms/common/Header'

type Props = {}

const Admin = (props: Props) => {
  const user_info = useRecoilValue(userAtom)
  if(user_info["https://mein.benu.at/roles"][0] ==="Admin"){
    return (
      <Layout>
      <HeaderInternal>
  <img
    src="https://res.cloudinary.com/dkyl4jbxx/image/upload/v1644325479/mein/assets/admin1image.png"
    alt="header"
  />
  <div className="area">
    <p className="title">
      Admin
    </p>
    <p className="info">
      Title here
    </p>
  </div>
  </HeaderInternal>
  

  </Layout>
  
    )
  }else{
    return (
      <Layout>

      </Layout>
    )
  }
}

const HeaderInternal = styled.div`
  /* background-image: url("https://res.cloudinary.com/dkyl4jbxx/image/upload/v1643917716/mein/assets/flowers.png"); */
  ${tw`bg-right-top flex flex-col space-y-2 md:space-y-0 md:flex-row justify-around items-end mb-6`}
  img {
    ${tw`h-auto w-4/6 mx-auto md:w-1/4`}
  }
  .area {
    ${tw`w-full md:w-3/4 space-y-2`}
    .title {
      ${tw`text-4xl font-serif text-primary-4`}
    }
    span {
      ${tw`text-4xl font-serif text-black`}
    }
  }
  .info {
    ${tw`text-lg font-sans tracking-wider`}
  }
`;

export default Admin