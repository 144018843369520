/* eslint-disable react-hooks/rules-of-hooks */
import { useSetRecoilState } from "recoil";
import axiosApi from "../../../api/axiosApi";
import { IObituaryItem, TObituaryComment, TObituaryImage, TObituaryLocationProps } from "../../../resources";
import { obituaryAtom } from "../../atoms";

export const newObituaryTemplate: IObituaryItem = {
  template: "default",
  name: "",
  photo: null,
  birthDate: "",
  deathDate: "",
  location: "",
  bio: "<p></p><p></p>",
  gallery: [],
  serviceLocation: {
    type: "service",
  },
  receptionLocation: {
    type: "reception",
  },
  published: false,
  enableTributes: true,
  enableComments: true,
  enableReplies: true,
  modalService: false,
  modalReception: false,
  tributes: [],
  comments: []
};

export const useObituaryCreate = () => {
  const setObituary = useSetRecoilState<IObituaryItem>(obituaryAtom);
  return (tempObituary: IObituaryItem) => {
    setObituary(tempObituary);
  };
};
export const useOpenLocation = () => {
  const setObituary = useSetRecoilState<IObituaryItem>(obituaryAtom);
  return (locationType: string = "null") => {
    setObituary((oldState) => {
      return {
        ...oldState,
        modalService: locationType === "service" ? true : false,
        modalReception: locationType === "reception" ? true : false,
      };
    });
  };
};

export const removeComment = () => {
  const setObituary = useSetRecoilState<IObituaryItem>(obituaryAtom);
  return (comment: TObituaryComment) => {
    setObituary((oldState) => {
      let comments = oldState.comments.filter(c => c.id !== comment.id)

      return {
        ...oldState,
        comments : comments
      };
    });
  };
}

export const useToogleOptions = () => {
  const setObituary = useSetRecoilState<IObituaryItem>(obituaryAtom);
  return (locationType: string = "null") => {
    setObituary((oldState) => {

      let toogle = {};
      if (locationType === "tributes") {
        toogle = {
          enableTributes: !oldState.enableTributes
        };
      }

      if (locationType === "comments") {
        toogle = {
          enableComments: !oldState.enableComments
        };
      }

      if (locationType === "replies") {
        toogle = {
          enableReplies: !oldState.enableReplies
        };
      }

      return {
        ...oldState,
        ...toogle
      };
    });
  };
}


export const useUserPhoto = () => {
  const setObituary = useSetRecoilState<IObituaryItem>(obituaryAtom);
  return (photo: TObituaryImage) => {
    setObituary((oldState) => {
      return {
        ...oldState,
        photo: photo,
      }
    })
  }
}
export const useCleanUserPhoto = () => {
  const setObituary = useSetRecoilState<IObituaryItem>(obituaryAtom);
  return () => {
    setObituary((oldState) => {
      return {
        ...oldState,
        photo: null,
      }
    })
  }
}

 export const useAddGalleryPhoto = () => {
  const setObituary = useSetRecoilState<IObituaryItem>(obituaryAtom);
  return (photo:TObituaryImage) => {
    console.log(photo)
    setObituary((oldState) => {
      let gallery = [...oldState.gallery];
      gallery.push(photo);
      return {
        ...oldState,
        gallery
      }
    })
  }
 }

 export const useRemoveGalleryPhoto = () => {
  const setObituary = useSetRecoilState<IObituaryItem>(obituaryAtom);
  return (photo:TObituaryImage) => {
    setObituary((oldState) => {
      let gallery = oldState.gallery.filter((item) => item.url !== photo.url)
      return {
        ...oldState,
        gallery
      }
    })
  }
 }

export const useCleanLocation = () => {
  const setObituary = useSetRecoilState<IObituaryItem>(obituaryAtom);
  return (locationType: string) => {
    setObituary((oldState) => {
      let clean = {};
      if (locationType === "service") {
        clean = {
          serviceLocation: {
            type: "service",
          },
        };
      }
      if (locationType === "reception") {
        clean = {
          receptionLocation: {
            type: "reception",
          },
        };
      }

      return {
        ...oldState,
        ...clean
      };
    });
  };
};

export const useSaveLocation = () => {
  const setObituary = useSetRecoilState<IObituaryItem>(obituaryAtom);
  return (locationType: string, location: TObituaryLocationProps) => {
    setObituary((oldState) => {
    let saveLocation = {};
    if (locationType === "service") {
      saveLocation = {
        serviceLocation: {
          type: "service",
          ...location
        },
      };
    }
    if (locationType === "reception") {
      saveLocation = {
        receptionLocation: {
          type: "reception",
          ...location
        },
      };
    }

    return {
      ...oldState,
      ...saveLocation
    };
  });
};
};

export const loadObituary = async (id: string | undefined) => {
  const setObituary = useSetRecoilState<IObituaryItem>(obituaryAtom);
  const obituaryItem: IObituaryItem = await axiosApi
    .get(`/obituary/${id}`)
    .then((res) => {
      console.log(res);
      return res.data;
    });
  return (obituary: IObituaryItem) => {
    setObituary(obituaryItem);
  };
};

