/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useRecoilValue, useSetRecoilState} from "recoil";
import styled from "styled-components";
import tw from "twin.macro";
import axiosApi from "../../api/axiosApi";
import {dashboardAtom, userAtom} from "../../state/atoms";
// import { useAuth0 } from "@auth0/auth0-react";
// import DashboardObituaryTempItem from "../../components/molecules/dashboard/DashboardObituaryTempItem";

type DashboardProps = {};

const Dashboard: React.FC<DashboardProps> = (props: DashboardProps) => {
  const setDashboard = useSetRecoilState(dashboardAtom);
  const user_info = useRecoilValue(userAtom);
  const {t} = useTranslation();
  let history = useNavigate();

  useEffect(() => {
    window.location.href = `${process.env.REACT_APP_MAIN_SITE}/dashboard/`;
  });

  useEffect(() => {
    if (user_info.session_token) {
      loadDashboard();
    }
  }, [user_info]);

  const loadDashboard = async () => {
    if (user_info.email_verified) {
      await axiosApi.get("/dashboard", {}).then((res) => {
        res.data.isLoaded = true;
        setDashboard(res.data);
      });
    } else {
      history("/email_confirmation");
    }
  };

  return (
    <></>
    // <Layout>
    //     <CreateObituaryModal />

    //   <HeaderDashboard>
    //     <img
    //       src="https://res.cloudinary.com/dkyl4jbxx/image/upload/v1643917716/mein/assets/dash_Header.png"
    //       alt="header"
    //     />
    //     <div className="area">
    //       <p className="title">
    //         {t('dashboard.title')} <span className="user">{user_info?.name}</span>
    //       </p>

    //       <p className="info">
    //       {t('dashboard.subtitle')}
    //       </p>
    //     </div>
    //   </HeaderDashboard>
    //   <GridArea2>
    //     <DasboardObituaryList  />
    //     <DasboardPlanningList  />
    //   </GridArea2>
    //   <GridArea3></GridArea3>
    // </Layout>
  );
};

const HeaderDashboard = styled.div`
  background-image: url("https://res.cloudinary.com/dkyl4jbxx/image/upload/v1643917716/mein/assets/flowers.png");
  background-size: 80%;
  ${tw`bg-right-top bg-no-repeat flex flex-col space-y-2 md:space-y-0 md:flex-row justify-around items-end mb-6`}
  img {
    ${tw`h-auto w-1/2 mx-auto md:w-1/6`}
  }
  .area {
    ${tw`w-full md:w-9/12 space-y-2`}
    .title {
      ${tw`text-4xl font-serif text-primary-4`}
    }
    span {
      ${tw`text-4xl font-serif text-black`}
    }
  }
  .info {
    ${tw`text-lg font-sans tracking-wider`}
  }
`;
const GridArea2 = styled.div`
  ${tw`grid grid-cols-2 lg:grid-cols-6 gap-4 w-full`}
`;

const GridArea3 = styled.div`
  ${tw`grid grid-cols-3 gap-4 w-full`}
`;
export default Dashboard;
