import React, { useEffect, useState } from "react";
import { FieldProps } from "formik";
import styled from "styled-components";
import tw from "twin.macro";

type CustomInputComponentProps = {
  type?: string;
  label?: string;
};

const CustomInputComponent: React.FC<
  CustomInputComponentProps & FieldProps
> = ({
  field,
  form: { touched, errors, handleBlur, handleChange },
  type = "text",
  label,
  ...props
}) => {
  const [hideLabel, setHideLabel] = useState(field.value > 0 ? true : false);
  const [error, setError] = useState(false);
  useEffect(() => {
    if (touched[field.name] && errors[field.name]) {
      setError(true);
    } else {
      setError(false);
    }
  }, [errors, field.name, touched]);
  useEffect(() => {
    if (field.value) {
      setHideLabel(true);
    }
  }, [field]);
  return (
    <CustomInputArea error={error}>
      <CustomInput
        type={type}
        {...field}
        {...props}
        onFocus={(e) => {
          setHideLabel(true);
        }}
        onChange={(e) => handleChange(e)}
        onBlur={(e) => {
          handleBlur(e);
          e.target.value.length === 0
            ? setHideLabel(false)
            : setHideLabel(true);
        }}
        onKeyPress={e => { e.which === 13 && e.preventDefault() }}
        placeholder=""
        autoComplete="off"
        hide={hideLabel}
      />
      <CustomLabel hide={hideLabel}>{label}</CustomLabel>
      {error && <ErrorArea>{errors[field.name]}</ErrorArea>}
    </CustomInputArea>
  );
};
type LabelProps = {
  error?: boolean;
  hide?: boolean;
};
const CustomInputArea = styled.div<LabelProps>`
  ${tw`flex flex-col w-full relative bg-white border border-solid h-14 rounded-lg`}
  ${(props) => (props.error ? tw`border-secondary` : tw`border-primary-3`)}
`;
const CustomInput = styled.input<LabelProps>`
  ${tw`bg-white h-full pt-5 px-3 outline-none cursor-text  rounded-md font-sans tracking-wide relative border-0 text-base`}
  &[type="date"]::-webkit-calendar-picker-indicator, &[type="time"]::-webkit-calendar-picker-indicator {
    ${tw`opacity-25 pb-5`}
  }
  &[type="date"]::-webkit-datetime-edit,
  &[type="time"]::-webkit-datetime-edit {
    ${(props) => (props.hide ? tw`text-black` : tw`text-transparent`)};
  }
`;
const ErrorArea = styled.div`
  ${tw`text-xs absolute top-0 right-0 bg-secondary/70 text-white px-1 rounded-tr rounded-bl`}
`;
const CustomLabel = styled.label<LabelProps>`
  ${tw`absolute font-sans left-3 transition-all duration-300 ease-in-out pointer-events-none`}
  ${(props) =>
    props.hide
      ? tw`top-0.5 text-primary-4 text-sm tracking-normal`
      : tw`top-4 text-black-40 text-base tracking-wide`}
`;

export default CustomInputComponent;
