import { useAuth0 } from '@auth0/auth0-react';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';
import { ColorProps, SizeProps, VisualProps } from '../../../resources';
import ButtonComponent from '../../atoms/common/ButtonComponent';
import meinBenuLogo from "./../../../assets/svgs/meinBenuLogo.svg";

type Props = {}

const Navbar = (props: Props) => {

  const {t} = useTranslation()
  const { loginWithRedirect } = useAuth0();

  const scrollTo = (idValue:string) => {
    if(window){
      var element = document.getElementById(idValue);
      var headerOffset = window.innerWidth > 480 ? 130: 90
      if(element){
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    
      window.scrollTo({
           top: offsetPosition,
           behavior: "smooth"
      });
    }
    }
  }

  return (
    <HeaderSection id="navbar">
      <HeaderContent>
        <HeaderLogoComponent to="/dashboard">
          <img src={meinBenuLogo} alt="" />
        </HeaderLogoComponent>
        <MenuContainer>
          <MenuItem onClick={()=>{scrollTo("obituaries")}}>{t('landing.menu.obituariesLabel')}</MenuItem>
          <MenuItem onClick={()=>{scrollTo("plannings")}}>{t('landing.menu.planningsLabel')}</MenuItem>
          <MenuItem onClick={()=>{scrollTo("faqs")}}>{t('landing.menu.faqsLabel')}</MenuItem>
          <MenuItem onClick={()=>{loginWithRedirect()}}>{t('landing.menu.loginLabel')}</MenuItem>
          <ButtonComponent
            visual={VisualProps.SOLID}
            size={SizeProps.MEDIUM}
            color={ColorProps.PRIMARY}
            dataCi={"navbar-cta-button"}
            onClick={()=>{loginWithRedirect()}}
          >
            {t('landing.menu.signupLabel')}
          </ButtonComponent>
        </MenuContainer>
      </HeaderContent>
    </HeaderSection>
  )
}

const HeaderLogoComponent = styled(Link)`
  ${tw`flex flex-row flex-grow h-full px-4`}
  img {
    ${tw`h-14 my-auto`}
  }
`;

const HeaderSection = styled.header`
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  ${tw`sticky z-10 top-0 w-full bg-opacity-60 h-24 flex flex-row justify-center items-center bg-primary-2 -mb-24`}
`;

const HeaderContent = styled.div`
  ${tw`w-full xl:container h-full flex flex-row justify-between items-center py-2`}
`

const MenuContainer = styled.div`
  ${tw`flex flex-row items-center space-x-12 `}
`

const MenuItem = styled.button`
  ${tw`hidden md:flex justify-center items-center border-0 outline-none bg-transparent text-black text-sm opacity-60 cursor-pointer hover:opacity-100 transition-opacity duration-200`}
`

export default Navbar