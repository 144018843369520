// import { useAuth0 } from '@auth0/auth0-react'
import { useAuth0 } from '@auth0/auth0-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import tw from 'twin.macro'
import { ColorProps, SizeProps, VisualProps } from '../../../resources'
import ButtonComponent from '../../atoms/common/ButtonComponent'
import FaqItem from '../../molecules/landing/FaqItem'
// import { ColorProps, SizeProps, VisualProps } from '../../../resources'
// import ButtonComponent from '../../atoms/common/ButtonComponent'
import TitleComponent from '../../molecules/landing/TitleComponent'

type TFaqItem = {
  question: string
  answer: string
}

type Props = {}

const Faqs = (props: Props) => {

  const {t} = useTranslation()
  const { loginWithRedirect } = useAuth0();
  const [position, setPosition] = useState(0)
  const faqItems : Array<TFaqItem> = t('landing.faqs.items', { returnObjects: true });
    return (
      <BlockContainer>
        <Container>
          <InfoConteiner>
            <TitleComponent
                title={t('landing.faqs.title')}
                subtitle={t('landing.faqs.subtitle')}
                id="faqs"
            >
              <div>
                            <ButtonComponent
                visual={VisualProps.SOLID}
                size={SizeProps.BIG}
                color={ColorProps.PRIMARY}
                dataCi={"header-cta-button"}
                onClick={()=>{loginWithRedirect()}}
              >
                {t('landing.faqs.signUpLabel')}
              </ButtonComponent>
              </div>
            </TitleComponent>
          </InfoConteiner>
          <FaqsContainer>
            <FaqList>
              {faqItems.map((item, index) => <FaqItem
              position={index}
              setPosition={setPosition}
              actualPosition={position}  
              {...item}/>)}
            </FaqList>
          </FaqsContainer>
          <ImageContainer>
            <img src='https://res.cloudinary.com/dkyl4jbxx/image/upload/v1657291272/mein/assets/faqImage.png' alt="faqs"/>
          </ImageContainer>
        </Container>
      </BlockContainer>
    )
  }

  const Container = styled.div`
    ${tw`w-full xl:container mx-auto grid grid-cols-1 md:grid-cols-2 md:gap-32 px-4`}
    div{
      ${tw`flex my-auto`}
    }
  `
  const BlockContainer = styled.div`
    ${tw`flex flex-col md:flex-row bg-orange`}
  `
  const InfoConteiner = styled.div`
    ${tw`flex flex-col pt-24 space-y-12`}
  `

  const FaqsContainer = styled.div`
  ${tw`flex flex-col row-span-2 h-full py-8 justify-start items-start`}
  `

  const FaqList = styled.div`
    ${tw`flex flex-col bg-white shadow-lg p-2`}
  `

  const ImageContainer = styled.div`
    ${tw`w-full h-auto mt-auto`} 
    img{
      ${tw`w-full h-auto mt-auto`}
    }
  `

export default Faqs