import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import tw from 'twin.macro'
import { ColorProps, SizeProps, VisualProps } from '../../../resources'
import ButtonComponent from '../../atoms/common/ButtonComponent'
import TitleComponent from '../../molecules/landing/TitleComponent'

type Props = {}

const Header = (props: Props) => {

  const {t} = useTranslation()
  const { loginWithRedirect } = useAuth0();


  return (
    <BlockContainer>
      <Container>
          <TitleComponent
            title={t('landing.header.title')}
            subtitle={t('landing.header.subtitle')}
            id="header"
          >
            <div>
            <ButtonComponent
            visual={VisualProps.SOLID}
            size={SizeProps.BIG}
            color={ColorProps.PRIMARY}
            dataCi={"header-cta-button"}
            onClick={()=>{loginWithRedirect()}}
          >
            {t('landing.header.signUpLabel')}
          </ButtonComponent>
          </div>
          </TitleComponent>
        <ImageContainer>
          <img src='https://res.cloudinary.com/dkyl4jbxx/image/upload/v1657281659/mein/assets/headerImage.png' alt="plannings"/>
        </ImageContainer>
      </Container>
    </BlockContainer>
  )
}


const Container = styled.div`
${tw`w-full xl:container mx-auto mt-10 mb-2 md:my-2 grid grid-cols-1 md:grid-cols-2 md:gap-32 px-4`}
div{
  ${tw`flex my-auto`}
}
`
const BlockContainer = styled.div`
  ${tw`flex flex-col md:flex-row bg-primary-2 pt-24 bg-primary-2 `}
`
const ImageContainer = styled.div`
${tw`w-full h-auto pt-20`} 
img{
      ${tw`w-full h-auto`}
    }
`

export default Header