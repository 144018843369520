import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import Dropzone, {
  IDropzoneProps,
  ILayoutProps,
} from "react-dropzone-uploader";
import FileUploadLabel from "../../atoms/form/FileUploadLabel";
import { TObituaryImage } from "../../../resources";
import { AiOutlineClose } from "react-icons/ai";
import { resizeImage } from "../../../lib/images";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { obituaryAtom, userAtom } from "../../../state/atoms";
import {
  useAddGalleryPhoto,
  useRemoveGalleryPhoto,
} from "../../../state/hooks/obituary/obituary";
import { t } from "i18next";

type Props = {};

const Layout = ({
  input,
  dropzoneProps,
  files,
  extra: { maxFiles },
}: ILayoutProps) => {
  return (
    <>
      <div {...dropzoneProps}>{files.length <= maxFiles && input}</div>
    </>
  );
};

const DropAreaGallery = (props: Props) => {
  const [obituary] = useRecoilState(obituaryAtom);
  const user_info = useRecoilValue(userAtom);
  const [tempList, setTempList] = useState<any[]>([]);
  const addGalleryPhoto = useAddGalleryPhoto();

  const onChangeStatus: IDropzoneProps["onChangeStatus"] = (
    fileWithMeta,
    status,
    items
  ) => {
    console.log(status);
    if (status === "preparing") {
      setTempList([
        ...tempList,
        {
          id: fileWithMeta.meta.id,
          url: "",
        },
      ]);
    } else if (status === "getting_upload_params") {
      let tempAry = [...tempList];
      // eslint-disable-next-line array-callback-return
      tempAry.map((item) => {
        if (item.url === "") {
          item.url = items.filter(
            (a) => a.meta.id === item.id
          )[0].meta.previewUrl;
        }
      });
      setTempList(tempAry);
    } else if (status === "done") {
      setTempList(
        tempList.filter((item) => item.id !== String(fileWithMeta.meta.id))
      );
      toast.success("Upload done!");
      let { result } = JSON.parse(fileWithMeta.xhr?.response);
      addGalleryPhoto({
        url: result.url,
        width: result.width,
        height: result.height,
      });
    } else if (status === "error_file_size") {
      setTempList(
        tempList.filter((item) => item.id !== String(fileWithMeta.meta.id))
      );
      toast.success("Upload Error! - Max File Size is 8mb!");
    }
  };

  const getUploadParams: IDropzoneProps["getUploadParams"] = (props) => {
    let body = new FormData();
    body.append("profile-file", props.file);
    body.append("folder", `mein/${String(user_info?.sub).split("|")[1]}`);
    return { url: "https://old.benu.at/api/upload/single/", body };
  };

  return (
    <>
      <GalleryTitle>{t("obituary.form.galleryPlaceholder")} </GalleryTitle>
      <Container>
        {((obituary.gallery != null && obituary.gallery.length > 0) ||
          tempList.length > 0) && (
          <ImageListContainer>
            {obituary.gallery.map((item: TObituaryImage, index: number) => (
              <RenderImage {...item} key={index} />
            ))}
            {tempList.map((item: TObituaryImage) => (
              <RenderTempImage {...item} />
            ))}
          </ImageListContainer>
        )}
        <Dropzone
          getUploadParams={getUploadParams}
          onChangeStatus={onChangeStatus}
          LayoutComponent={Layout}
          accept="image/*"
          minSizeBytes={0}
          maxSizeBytes={8388608}
          styles={{
            dropzone: { minHeight: 200, maxHeight: 250 },
          }}
          data-ci="test"
          multiple={true}
          maxFiles={30}
          key="Dropzone-photo-gallery"
          inputContent={
            <FileUploadLabel
              dataCi="drop-gallery-container"
              multiple={true}
              label={t("obituary.form.galleryDropdownAreaPlaceholder")}
              key={Math.random()}
            />
          }
          inputWithFilesContent={
            <FileUploadLabel
              dataCi="drop-gallery-container"
              multiple={true}
              label={t("obituary.form.galleryDropdownAreaPlaceholder")}
              key={Math.random()}
            />
          }
        />
      </Container>
    </>
  );
};

const RenderImage = ({ id, url, width, height }: TObituaryImage) => {
  const removeGalleryPhoto = useRemoveGalleryPhoto();

  return (
    <ItemImageContainer>
      <img src={resizeImage(url, 120, 120)} alt="" />
      <button type="button" onClick={() => removeGalleryPhoto({ id, url })}>
        <AiOutlineClose />
      </button>
    </ItemImageContainer>
  );
};
const RenderTempImage = ({ id, url, width, height }: TObituaryImage) => {
  return (
    <ItemImageTempContainer>
      <img src={url} alt="" />
    </ItemImageTempContainer>
  );
};

const ImageListContainer = styled.div`
  ${tw`grid grid-cols-3 md:grid-cols-6 lg:grid-cols-8 gap-4 mb-2`}
`;

const ItemImageContainer = styled.div`
  ${tw`relative bg-white w-full h-28 shadow-md flex flex-col rounded-lg`}
  img {
    ${tw`relative h-full w-full object-cover m-0 p-0 border border-solid border-primary-4/50 rounded-lg`}
  }
  button {
    ${tw`absolute p-1 w-8 h-6 bg-secondary hover:bg-secondary-dark top-0 right-0 border-0 outline-none cursor-pointer rounded-tr-lg rounded-bl-lg text-white`}
    svg {
      ${tw`w-4 h-4`}
    }
  }
`;
const ItemImageTempContainer = styled.div`
  ${tw`bg-white w-full h-28`}
  img {
    ${tw`h-full w-full object-cover m-0 p-0 opacity-20 border border-solid border-primary-4/50`}
  }
`;
const Container = styled.div`
  ${tw`p-3 bg-primary-3 `}
  .dzu-dropzone {
    ${tw`bg-white/60 rounded-md overflow-scroll flex flex-col items-center justify-center w-full relative transition-all ease-in-out duration-150 border-dashed border border-primary-3 overflow-hidden `}
  }

  .dzu-dropzoneActive {
    ${tw`border-solid border-secondary bg-primary-2/60`}
  }

  .dzu-dropzoneDisabled {
    opacity: 0.5;
  }

  .dzu-dropzoneDisabled *:hover {
    cursor: unset;
  }

  .dzu-input {
    display: none;
  }
`;
const GalleryTitle = styled.div`
  ${tw`px-4 text-primary-4 pb-1`}
`;
export default DropAreaGallery;
