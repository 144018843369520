import React from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { useRecoilState, useRecoilValue } from 'recoil'
import styled from 'styled-components'
import tw from 'twin.macro'
import axiosApi from '../../../api/axiosApi'
import { formatTimeToNow } from '../../../lib/dates'
import { ColorProps, SizeProps, VisualProps } from '../../../resources'
import { commentModalAtom, obituaryAtom } from '../../../state/atoms'
import { removeComment } from '../../../state/hooks/obituary/obituary'
import ButtonComponent from '../../atoms/common/ButtonComponent'
import ModalContainer from '../../atoms/common/ModalContainer'

type Props = {}

const CommentDelete = (props: Props) => {
  const obituary = useRecoilValue(obituaryAtom)
  const [commentModal, setCommentModal] = useRecoilState(commentModalAtom)
  const removeObituaryComment = removeComment()
  const closeModal = () => {
    setCommentModal({
      open: false,
      payload: {}
  })}
  const deleteComment = async () => {
    await axiosApi.delete(`/obituary/${obituary.id}/comments/${commentModal.payload.id}`)
    .then(res => console.log(res.data))
    removeObituaryComment(commentModal.payload)
    closeModal()
  }
  if(commentModal.open){
    return <ModalContainer closeModal={()=>closeModal()}>
      <Container>
        <ModalHeaderArea>
          Delete comment
            <button type="button" onClick={() => closeModal()}>
              <AiOutlineClose />
            </button>
        </ModalHeaderArea>
        <p>Do you want to remove this comment?</p>
        <p>{commentModal.payload.message}</p>
        <p>{commentModal.payload.name} - {formatTimeToNow(commentModal.payload.timestamp)}</p>
        <ButtonComponent  
            visual={VisualProps.SOLID}
            size={SizeProps.MEDIUM}
            color={ColorProps.PRIMARY}
            dataCi="confirm-delete-comment"
        onClick={()=>deleteComment()}>Confirm Delete</ButtonComponent>
        </Container>    
    </ModalContainer> 
  }else{
    return <></>
  }
}


const Container = styled.div`
  ${tw`bg-gradient-to-b from-primary-2  via-white to-white w-full flex flex-col items-center justify-between w-[85vw] md:w-auto h-[16rem] p-4 space-y-4 rounded-lg`}
`;


const ModalHeaderArea = styled.div`
  ${tw`flex flex-row justify-between items-center font-serif text-2xl pt-2 px-2 md:px-4 w-full`}
  button {
    ${tw`bg-transparent border-0 outline-none text-secondary cursor-pointer transition-all hover:text-secondary-dark h-8 w-8 flex flex-row justify-end items-center`}
    svg {
      ${tw`w-6 h-6`}
    }
  }
`;

export default CommentDelete