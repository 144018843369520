
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
// import { RootStateOrAny, useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import tw from "twin.macro";
import {
  TObituaryLocation,
  TObituaryLocationProps,
  VisualProps,
  ColorProps,
  SizeProps,
} from "../../../resources";
import ButtonComponent from "../../atoms/common/ButtonComponent";
import CustomInputComponent from "../../atoms/form/CustomInputComponent";
import CustomTextAreaComponent from "../../atoms/form/CustomTextAreaComponent";
import GooglePlaceField from "../../atoms/form/GooglePlaceField";
import { RiMap2Line } from "react-icons/ri";
import { useRecoilValue } from "recoil";
import { obituaryAtom } from "../../../state/atoms";
import { useOpenLocation, useSaveLocation } from "../../../state/hooks/obituary/obituary";
import { t } from "i18next";
import * as Yup from "yup";


type Props = {};



const ObituaryLocationEvent = (props: Props) => {
  // const { obituary } = useSelector((state: RootStateOrAny) => state);
  const [obituaryLocation, setObituaryLocation] = useState<TObituaryLocationProps>({});
  // const dispatch = useDispatch();
  const obituary = useRecoilValue(obituaryAtom)
  const closeModal = useOpenLocation()
  const saveLocation = useSaveLocation()

  const FormSchema = Yup.object().shape({
    date: Yup.date()
      .required( t("obituary.form.alerts.name.required")),
    time: Yup.string()
      .required(t("obituary.form.alerts.birthDate.required")),
  });


  const [location, setLocation] = useState<TObituaryLocation>({});

  let initialValues: TObituaryLocationProps = {
    date: obituaryLocation?.date ? obituaryLocation.date : "",
    time: obituaryLocation?.time ? obituaryLocation.time : "",
    mainInfo: obituaryLocation.mainInfo ? obituaryLocation.mainInfo : "",
    alternativeInfo: obituaryLocation.alternativeInfo
      ? obituaryLocation.alternativeInfo
      : "",
  };

  useEffect(() => {
    if (obituary.modalService) {
      setObituaryLocation(obituary.serviceLocation as TObituaryLocationProps);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      initialValues = { ...initialValues, ...obituary.serviceLocation };
      setLocation(
        obituary.serviceLocation?.location
          ? obituary.serviceLocation.location
          : {}
      );
    } else if (obituary.modalReception) {
      setObituaryLocation(obituary.receptionLocation as TObituaryLocationProps);
      initialValues = { ...initialValues, ...obituary.receptionLocation };
      setLocation(
        obituary.receptionLocation?.location
          ? obituary.receptionLocation.location
          : {}
      );
    }
  }, [obituary]);
  useEffect(() => {}, [location]);
  if (!obituaryLocation.type) {
    return <p>loading...</p>;
  }

  return (
    <ObituaryLocationContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={FormSchema}
        onSubmit={(values, actions) => {
          console.log(values,location)
          if (obituaryLocation.type === "reception") {
            saveLocation(obituaryLocation.type, {...values, location} );
          } else if (obituaryLocation.type === "service") {
            saveLocation("service", {...values, location} );
          }
          closeModal()
        }
        }
      >
        {({ errors, touched }) => (
          <Form>
            <FormContainer>
              <ObituaryLocationHeaderArea>
              {t(`obituary.form.${obituaryLocation.type}.title`)}
                <button type="button" onClick={() => closeModal()}>
                  <AiOutlineClose />
                </button>
              </ObituaryLocationHeaderArea>
              <FormBlock>
                <FieldsArea>
                  <Field
                    key={1}
                    data-ci={`${obituaryLocation.type}-date`}
                    component={CustomInputComponent}
                    type="date"
                    name="date"
                    label={t(`obituary.form.${obituaryLocation.type}.datePlaceholder`)}
                  />
                  <Field
                    key={2}
                    data-ci={`${obituaryLocation.type}-time`}
                    component={CustomInputComponent}
                    type="time"
                    name="time"
                    label={t(`obituary.form.${obituaryLocation.type}.timePlaceholder`)}
                  />
                  <Col2>
                    <Field
                      key={3}
                      component={CustomTextAreaComponent}
                      data-ci={`${obituaryLocation.type}-mainInfo`}
                      type="textarea"
                      name="mainInfo"
                      label={t(`obituary.form.${obituaryLocation.type}.mainInfoPlaceholder`)}
                    />
                  </Col2>
                  <Col2>
                    <Field
                      key={5}
                      component={CustomTextAreaComponent}
                      data-ci={`${obituaryLocation.type}-alternativeInfo`}
                      type="textarea"
                      name="alternativeInfo"
                      label={t(`obituary.form.${obituaryLocation.type}.additionalInfoPlaceholder`)}
                    />
                  </Col2>
                </FieldsArea>
                <FieldsArea>
                  <MapContainer>
                    {!location.lat ? (
                      <>
                        <GooglePlaceField
                          setValue={setLocation}
                          dataCi={`${obituaryLocation.type}-findLocation`}
                          label={t(`obituary.form.${obituaryLocation.type}.findLocationPlaceholder`)}
                        />
                        <MapIcon>
                          <RiMap2Line />
                          <p>{t(`obituary.form.${obituaryLocation.type}.findLocationIcon`)}</p>
                        </MapIcon>
                      </>
                    ) : (
                      <MapResultContainer>
                        <p className="title">{location.name}</p>
                        <p>{`${location.route}, ${location.street_number} - 
       ${location.locality}, ${location.postal_code}`}</p>
                        <img
                          src={`
                  https://cdn.benu.at/map?size=400x330&markers=icon:https://res.cloudinary.com/dkyl4jbxx/image/upload/w_48,f_auto/v1610720622/extensions/googlemaps/default.png%7C${location.lat},${location.lng}`}
                          alt=""
                        />
                        <p className="footer">{t(`obituary.form.${obituaryLocation.type}.findLocationErrorPlaceholder`)}{" "}<span onClick={()=>{setLocation({})}}>{t(`obituary.form.${obituaryLocation.type}.findLocationErrorActionPlaceholder`)}</span></p>
                      </MapResultContainer>
                    )}
                  </MapContainer>
                </FieldsArea>
              </FormBlock>
              <ButtonsContainer>
              <ButtonComponent 
                visual={VisualProps.SOLID}
                color={ColorProps.PRIMARY}
                size={SizeProps.MEDIUM}
                onClick={()=>{closeModal()}}
                dataCi={`${obituaryLocation.type}-cancel`}
                >
                {t(`obituary.form.${obituaryLocation.type}.cancelButton`)}
                </ButtonComponent>
              <ButtonComponent 
                visual={VisualProps.SOLID}
                color={ColorProps.PRIMARY}
                size={SizeProps.MEDIUM}
                dataCi={`${obituaryLocation.type}-submit`}
                isSubmit={true}>
                  {t(`obituary.form.${obituaryLocation.type}.confirmButton`)}
                </ButtonComponent>
              </ButtonsContainer>
            </FormContainer>
          </Form>
        )}
      </Formik>
    </ObituaryLocationContainer>
  );
};

export default ObituaryLocationEvent;

const ObituaryLocationHeaderArea = styled.div`
  ${tw`flex flex-row justify-between items-center font-serif text-2xl pt-2 px-4`}
  button {
    ${tw`bg-transparent border-0 outline-none text-secondary cursor-pointer transition-all hover:text-secondary-dark h-8 w-8 flex flex-row justify-end items-center`}
    svg {
      ${tw`w-6 h-6`}
    }
  }
`;

const ButtonsContainer = styled.div`
  ${tw`flex flex-row flex-grow justify-between items-end px-2 pt-3 pb-1`}
`;

const MapResultContainer = styled.div`
  ${tw`flex flex-col flex-grow justify-between items-end`}
  p{
    ${tw`w-full text-left text-sm`}
  }
  .title{
    ${tw`font-serif text-lg`}
  }
  .footer{
    ${tw`text-sm text-primary-4 text-right`}
    span{
      ${tw`font-bold underline text-secondary cursor-pointer`}
    }
  }
`;

const ObituaryLocationContainer = styled.div`
  ${tw`w-[90vw] md:w-[800px] h-auto bg-primary-1`}
`;

const MapContainer = styled.div`
  ${tw`flex flex-col h-48 md:h-auto w-full col-span-2 row-span-3 flex-grow`}
  img {
    ${tw`w-full  h-24 md:h-48  object-cover rounded-lg border border-solid border-primary-3 mb-2`}
  }
`;
const MapIcon = styled.div`
  ${tw`flex flex-col justify-center items-center flex-grow text-primary-4 text-sm uppercase`}
  svg {
    ${tw`w-16 h-16 text-primary-2 m-2`}
  }
`;
const FormContainer = styled.div`
  ${tw`bg-gradient-to-b from-primary-2  via-white to-white w-full flex flex-col justify-between p-2`}
`;

const FieldsArea = styled.div`
  ${tw`w-full md:w-1/2 p-1 md:p-3 grid grid-cols-1 md:grid-cols-2 gap-2 justify-between`}
`;

const Col2 = styled.div`
  ${tw`col-span-2 flex-grow h-28`}
`;

const FormBlock = styled.div`
  ${tw`flex flex-col md:flex-row w-full `}
`;
