import React from "react";
import { Routes, Route } from "react-router-dom";
import { ProtectedRoute } from "../auth0/ProtectedRoute";
import Dashboard from "../pages/dashboard/Dashboard";
import Obituary from "../pages/obituaries/Obituary";
import Landing from "../pages/landing/Landing";
import ConfirmEmail from "../pages/support/ConfirmEmail";
import AdminObituaries from "../pages/admin/AdminObituaries";
import Admin from "../pages/admin/Admin";
// import AdminObituariesSearch from "../pages/admin/AdminObituariesSearch";

type Props = {};

export const RouterComponent: React.FC = (props: Props) => {
  return (
    <Routes>
        <Route index element={<Landing />} />
        <Route element={<ProtectedRoute component={Dashboard} />} path="dashboard" />  
        <Route element={<ProtectedRoute component={ConfirmEmail} />} path="email_confirmation" />  
        <Route path="obituary" >
          <Route element={<ProtectedRoute component={Obituary} />} path=":id" />
          <Route index element={<ProtectedRoute component={Obituary} />}  />
        </Route>
        <Route  path="admin">
          <Route path="obituaries" >
            {/* <Route element={<ProtectedRoute component={AdminObituaries} />} path=":id" /> */}
            <Route element={<ProtectedRoute component={AdminObituaries} />} index />
          </Route>
          <Route element={<ProtectedRoute component={Admin} />} index />
        </Route>
    </Routes>
  );
};
