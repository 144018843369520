import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import meinBenuLogo from "./../../../assets/svgs/meinBenuLogo.svg";
// import { FaChevronDown } from "react-icons/fa";
import { useAuth0 } from "@auth0/auth0-react";
import {ColorProps, SizeProps, VisualProps} from "../../../resources";
import ButtonComponent from "../../atoms/common/ButtonComponent";
import { Link, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userAtom } from "../../../state/atoms";
import { t } from "i18next";
// import { AiOutlineLogout } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import { RiAdminLine } from "react-icons/ri";

type HeaderProps = {};

const Header: React.FC<HeaderProps> = (props) => {
  const user_info = useRecoilValue(userAtom)
  const { isAuthenticated, logout } = useAuth0();
  const navigate = useNavigate()
  return (
    <HeaderComponent>
      <HeaderLogoComponent to="/dashboard">

        <img src={meinBenuLogo} alt="" />
        
      </HeaderLogoComponent>
      <HeaderUserComponent>
        {isAuthenticated
          && <>
              <img src={user_info?.picture} alt={user_info?.name}/>
              <p className="username">{user_info?.name}</p>

          {/* { <ButtonComponent
            visual={VisualProps.SOLID}
            color={ColorProps.PRIMARY}
            size={SizeProps.SMALL}
            center={true}
            dataCi="headerLogout"
            widthFull={true}
            onClick={() =>
              logout({ returnTo: window.location.origin })
            }
          >
            {t("menu.logoutLabel")}
          </ButtonComponent></>


        } */}
        {/* <LogoutButton 
                    onClick={() =>
                      logout({ returnTo: window.location.origin })
                    }
        > 
        </LogoutButton>*/}
        {user_info && user_info["https://mein.benu.at/roles"] && user_info["https://mein.benu.at/roles"][0] === "Admin" &&<ButtonComponent
            visual={VisualProps.SOLID}
            color={ColorProps.SECONDARY}
            size={SizeProps.SMALL}
            center={true}
            widthFull={true}
            dataCi = "headerLogin"
            onClick={() => {navigate(`/admin/obituaries`)}}
          >
          <RiAdminLine size={16} />
            Admin
          </ButtonComponent>}
          <ButtonComponent
            visual={VisualProps.SOLID}
            color={ColorProps.PRIMARY}
            size={SizeProps.SMALL}
            center={true}
            widthFull={true}
            dataCi = "headerLogin"
            onClick={() =>
              logout({ returnTo: window.location.origin })
            }
          >
          <FiLogOut size={16} />
            {t("menu.logoutLabel")}
          </ButtonComponent>
        </>
        }
      </HeaderUserComponent>
    </HeaderComponent>
  );
};

export default Header;

const HeaderComponent: React.FC = (props) => {
  return (
    <HeaderSection>
      <HeaderComplement left={true} />
      <HeaderContent>{props.children}</HeaderContent>
      <HeaderComplement left={false} />
    </HeaderSection>
  );
};

const HeaderSection = styled.header`
  ${tw`sticky z-10 top-0 w-full bg-opacity-60 h-20 flex flex-row justify-center shadow-md border-solid border-0 border-b border-black-20 items-center `}
`;
const HeaderContent = styled.div`
  ${tw`w-full xl:container h-full flex flex-row justify-between items-center`}
`;

// const LogoutButton = styled.div`
//   ${tw`flex flex-row justify-center items-center cursor-pointer bg-secondary text-white border-0 w-10 h-10 md:h-12 md:w-12 rounded-full`} 
// `

type HeaderComplementProps = {
  left: boolean;
};
const HeaderComplement = styled.div<HeaderComplementProps>`
  ${tw`w-auto h-full flex flex-grow`}
  ${(props) =>
    !props.left
      ? tw`bg-primary-3 bg-opacity-80`
      : tw`bg-primary-2 bg-opacity-80`}
`;

const HeaderUserComponent = styled.div`
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  ${tw`bg-primary-3/80 flex flex-row w-auto h-full items-center px-4 space-x-4`}

  img {
    ${tw`w-10 h-10 md:h-12 md:w-12 rounded-full`}
  }
  .username {
    ${tw`hidden md:flex font-medium tracking-wider`}
  }
  /* svg{
    ${tw`w-6 h-6`}
  } */
`;
const HeaderLogoComponent = styled(Link)`
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  ${tw`flex flex-row flex-grow h-full bg-primary-2/80 px-4`}
  img {
    ${tw`h-14 my-auto`}
  }
`;
// const HeaderNotificationComponent = styled.div`
//   -webkit-backdrop-filter: blur(10px);
//   backdrop-filter: blur(10px);
//   ${tw`bg-primary-3 bg-opacity-60 flex flex-row w-20 h-full `}
// `;
