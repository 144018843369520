import React from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import { FiTrash2 } from 'react-icons/fi';
import { TbHandStop } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';
import tw from 'twin.macro';
import axiosApi from '../../../api/axiosApi';
import { ColorProps, SizeProps, VisualProps } from '../../../resources';
import {deleteObituaryModalAtom, obituaryAtom } from '../../../state/atoms';
import ButtonComponent from '../../atoms/common/ButtonComponent';
import ModalContainer from '../../atoms/common/ModalContainer';
import {t} from "i18next";
type Props = {}

const DeleteObituaryModal = (props: Props) => {
  const obituary = useRecoilValue(obituaryAtom)
  const [deleteModal, setDeleteModal] = useRecoilState(deleteObituaryModalAtom)
  const closeModal = () => {
    // console.log(process.env.REACT_APP_MAIN_SITE)
    setDeleteModal({
      open: false,
  })}
  const navigate = useNavigate()

  const deleteObituary = async () => {
    await axiosApi.delete(`/obituary/${obituary.id}/`)
    .then(res => {
      closeModal()
      navigate(-1)
    })
  }

  if(deleteModal.open){
    return (
      <ModalContainer closeModal={() => closeModal()}> 
        <Container>
        <ModalHeaderArea>
            <h3>
              {t(`obituary.form.deleteObituaryTitle`)}
            </h3>
              <button type="button" onClick={() => closeModal()}>
                <AiOutlineClose />
              </button>
            </ModalHeaderArea>

            <p>{t(`obituary.form.deleteObituaryQuestion`)}</p>
            <h2>{obituary.name}</h2>
            <span>{t(`obituary.form.deleteObituaryWarning`)}</span>

            <ButtonContainer>
              <ButtonComponent
                visual={VisualProps.SOLID}
                size={SizeProps.MEDIUM}
                color={ColorProps.PRIMARY}
                dataCi="confirm-delete-obituary"
                onClick={() => deleteObituary()}
              >
               <FiTrash2/>
                {t(`obituary.form.deleteObituaryButton`)}
              </ButtonComponent>
              <ButtonComponent
                visual={VisualProps.OUTLINE}
                size={SizeProps.MEDIUM}
                color={ColorProps.PRIMARY}
                onClick={() => closeModal()}
                dataCi="cancel-delete-obituary"
              >
                <TbHandStop/>
                {t(`obituary.form.cancelDeleteButton`)}
              </ButtonComponent>
            </ButtonContainer>
        </Container>
      </ModalContainer>
    )
    }else{
      return <></>
    }
}


const Container = styled.div`
  ${tw`bg-gradient-to-b from-primary-2  via-white to-white  w-[90vw] md:w-[600px]  flex flex-col items-center justify-between p-4 space-y-8 rounded-lg`}
`;

const ModalHeaderArea = styled.div`
  ${tw`flex flex-row justify-between items-center w-full rounded-lg`}
  h3{
    ${tw`pl-8 font-serif  text-2xl pt-2 text-center w-full`}
  }
  button {
    ${tw`bg-transparent border-0 outline-none text-secondary cursor-pointer transition-all hover:text-secondary-dark h-8 w-8 flex flex-row justify-end items-center`}
    svg {
      ${tw`w-6 h-6`}
    }
  }
`;

const ButtonContainer = styled.div`
  ${tw`flex flex-col md:flex-row w-full justify-around space-y-2 md:space-y-0 md:space-x-2`}
`
export default DeleteObituaryModal