import { useAuth0 } from '@auth0/auth0-react'
import { t } from 'i18next'
import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { ColorProps, SizeProps, VisualProps } from '../../../resources'
import ButtonComponent from '../../atoms/common/ButtonComponent'
import StepContainer from '../../molecules/landing/StepContainer'
import TitleComponent from '../../molecules/landing/TitleComponent'

type Props = {}

const Obituaries = (props: Props) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <BlockContainer>
      <Container>
      <TitleComponent
        title={t('landing.obituary.title')}
        subtitle={t('landing.obituary.subtitle')}
        id="obituaries"
      >          
      </TitleComponent>
      <StepsContainer>
        <StepContainer
          image='https://res.cloudinary.com/dkyl4jbxx/image/upload/v1657281659/mein/assets/obituary1Image.png'
          title={t('landing.obituary.item01.title')}
          subtitle={t('landing.obituary.item01.subtitle')}
          />
          <StepContainer
          image="https://res.cloudinary.com/dkyl4jbxx/image/upload/v1657281659/mein/assets/obituary2Image.png"
          title={t('landing.obituary.item02.title')}
          subtitle={t('landing.obituary.item02.subtitle')}
          />
          <StepContainer
          image="https://res.cloudinary.com/dkyl4jbxx/image/upload/v1657281659/mein/assets/obituary3Image.png"
          title={t('landing.obituary.item03.title')}
          subtitle={t('landing.obituary.item03.subtitle')}
          />
      </StepsContainer>
      <div>
      <ButtonComponent
            visual={VisualProps.SOLID}
            size={SizeProps.BIG}
            color={ColorProps.PRIMARY}
            dataCi={"header-cta-button"}
            onClick={()=>{loginWithRedirect()}}
          >
            {t('landing.obituary.signUpLabel')}
          </ButtonComponent>
          </div>
      </Container>
    </BlockContainer>
  )
}

const Container = styled.div`
  ${tw`w-full xl:container mx-auto my-20 flex flex-col space-y-12 items-center px-2`}
`
const BlockContainer = styled.div`
  ${tw`flex flex-col md:flex-row bg-white`}
`
const StepsContainer = styled.div`
  ${tw`grid grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-12`}
`

export default Obituaries