/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import styled from 'styled-components'
import tw from 'twin.macro'
import axiosApi from '../../api/axiosApi'
import ButtonComponent from '../../components/atoms/common/ButtonComponent'
import Layout from '../../components/layout/Layout'
import AdminObituaryItem from '../../components/molecules/admin/AdminObituaryItem'
// import DashboardObituaryItem from '../../components/molecules/dashboard/DashboardObituaryItem'
import { ColorProps, IObituaryItem, SizeProps, VisualProps } from '../../resources'
import { adminObituaries, userAtom } from '../../state/atoms'
// import Header from '../../components/organisms/common/Header'

type Props = {}

const AdminObituaries = (props: Props) => {
  const user_info = useRecoilValue(userAtom)
  const [obituaries, setObituaries] = useRecoilState(adminObituaries)
  const [search, setSearch] = useState("")
  const [isSearch, setIsSearch] = useState(false)

  const loadObituaries = async () => {
    setObituaries(
      {entries:[], page:0, size:0, total:0 }
    )
    if (search !== "") {
      setIsSearch(true)
      await axiosApi.get(`/obituary/search?name=${search}&size=20`, {})
        .then(res => {
          setObituaries(res.data)
        }
        ) 
    } else {
      setIsSearch(false)
      await axiosApi.get("/obituary/search?size=20", {})
        .then(res => {
          setObituaries(res.data)
        }
        )
    }
  }

  const submitForm = (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    loadObituaries()
  }

  const loadMoreObituaries = async () => {
    if (search !== "") {
      await axiosApi.get(`/obituary/search?name=${search}&size=20&page=${obituaries.page+1}`, {})
        .then(res => {
          console.log(res.data)
          // setObituaries(res.data)
          setObituaries((oldState:any) => {
            return {
              ...oldState,
              entries: [...oldState.entries, ...res.data.entries],
              page: res.data.page
            };
          });
        }
        )
    } else {
      await axiosApi.get(`/obituary/search?size=20&page=${obituaries.page+1}`, {})
        .then(res => {
          setObituaries((oldState:any) => {
            return {
              ...oldState,
              entries: [...oldState.entries, ...res.data.entries],
              page: res.data.page
            };
          });
        }
        )
    }
  }
  useEffect(() => {
    document.title = 'Mein Benu - Admin - Obituaries';
  },)
  useEffect(() => {
    if (user_info.session_token) {
      loadObituaries()
    }
  }, [user_info])

  if (user_info && user_info["https://mein.benu.at/roles"] && user_info["https://mein.benu.at/roles"][0] === "Admin") {
    return (
      <Layout>
        <HeaderInternal>
        <img
    src="https://res.cloudinary.com/dkyl4jbxx/image/upload/v1644325479/mein/assets/admin1image.png"
    alt="header"
  />
          <div className="area">
            <p className="title">
              Obituaries Admin
            </p>

            <FormArea onSubmit={submitForm}>
        <FormElement><label htmlFor='search'>Search by name</label>
        <input onChange={(e)=>setSearch(e.target.value)} />
        </FormElement>
        <div>
        <ButtonComponent 
          color={ColorProps.PRIMARY}
          visual={VisualProps.SOLID}
          size={SizeProps.BIG}
          center={true}          // className={""}
          isSubmit={true}>
          search
        </ButtonComponent>
        </div>
        </FormArea>
        <p className="info">
        {searchInfo(isSearch, obituaries.total, search)}
            </p>

          </div>
        </HeaderInternal>
        {/* {user_info["https://mein.benu.at/roles"][0] ==="Admin" ? "true" : "false"} */}

        <ListContainer>
          {obituaries && obituaries.entries && obituaries.entries.map((item: IObituaryItem, index: number) => {
            return <AdminObituaryItem {...item} key={index} />;
          })}
        </ListContainer>
        { obituaries.page + 1 < obituaries.total / obituaries.size && <div><ButtonComponent 
          color={ColorProps.PRIMARY}
          visual={VisualProps.SOLID}
          size={SizeProps.BIG}
          center={true}          // className={""}
        onClick={(e) => {
          loadMoreObituaries()}}>
          load more
        </ButtonComponent></div>}
        
      </Layout>
    )
  } else {
    return <></>
  }
}

const searchInfo = (isSearch:boolean, value:number, term:string) => {
  if(isSearch){
    if(value === 0){
      return `No items were found with the term ${term}`
    }else if(value === 1){
      return `1 item was found with the term ${term}`
    }else{
      return `${value} items were found with the term ${term}`
    }
  }else{
    return `${value} items were found`
  }
}

const HeaderInternal = styled.div`
  /* background-image: url("https://res.cloudinary.com/dkyl4jbxx/image/upload/v1643917716/mein/assets/flowers.png"); */
  ${tw`bg-right-top flex flex-col space-y-2 md:space-y-0 md:flex-row justify-around items-end mb-6`}
  img {
    ${tw`h-auto w-4/6 mx-auto md:w-1/4`}
  }
  .area {
    ${tw`w-full md:w-3/4 space-y-2`}
    .title {
      ${tw`text-4xl font-serif text-primary-4`}
    }
    span {
      ${tw`text-4xl font-serif text-black`}
    }
  }
  .info {
    ${tw`text-lg font-sans tracking-wider`}
  }
`;

const FormArea = styled.form`
  ${tw`w-full p-3 bg-primary-1 flex flex-row space-x-4 items-end`}
`
const FormElement = styled.div`
  ${tw`w-full flex flex-col`}
  label{
    ${tw`opacity-60 text-base`}
  }
  input{
    ${tw`p-3 text-base text-black-80 rounded-lg border`}
  }
`

const ListContainer = styled.div`
  ${tw`flex-col h-auto grid grid-cols-2 lg:grid-cols-5 gap-3 p-3 bg-primary-2`}

`;

export default AdminObituaries