import React from 'react'
import { FiTrash2 } from 'react-icons/fi'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import tw from 'twin.macro'
import { formatTimeToNow } from '../../../lib/dates'
import { TObituaryComment, TObituaryReply } from '../../../resources'
import { commentModalAtom, replyModalAtom } from '../../../state/atoms'

const CommentItem = (props: TObituaryComment) => {
  const setCommentModal = useSetRecoilState(commentModalAtom)

  const handleDelete = () => {
    setCommentModal({
      open: true,
      payload: props
    })
  }

  return (
    <Container>
       <CommentArea>
          <div className="user-info">
            <p className="username">{props.name} - <span className='datetime'>{formatTimeToNow(props.timestamp as string)}</span></p>
            {props.image && <img src={props.image.url} alt="user"/>}
            <p>{props.message}</p>
          </div>
          <button data-ci={props.name} type="button" onClick={()=>handleDelete()} className="icon"><FiTrash2/></button>
       </CommentArea>
       {props.replies && <RepliesArea>
       {props.replies.map((reply, index)=><ReplyItem {...reply} key={index}/>)}   
      </RepliesArea>}
    </Container>
  )
}


const ReplyItem = (props: TObituaryReply) => {
  const setReplyModal = useSetRecoilState(replyModalAtom)

  const handleDelete = () => {
    setReplyModal({
      open: true,
      payload: props
    })
  }
  return(
    <ReplyArea>
      <div className="user-info">
        <p className="username">{props.name} - <span className='datetime'>{props.timestamp}</span></p>
        <p>{props.message}</p>
      </div>
      <button type="button" onClick={()=>handleDelete()} className="icon"><FiTrash2/></button>
    </ReplyArea>
  )
}

const Container = styled.div`
  ${tw`flex flex-col p-4 rounded-md bg-primary-1 mb-4 break-inside[avoid] w-full `}
`
const CommentArea = styled.div`
  ${tw`flex flex-row space-x-2 py-4 items-start justify-start w-full border-0`}
  .icon{
    ${tw`w-10 h-10 text-secondary/60 hover:text-secondary`}
    svg{
      ${tw`w-5 h-5`}
    }
  }
  img{
    ${tw`w-full h-36 object-cover my-2 rounded-sm border border-solid border-primary-4/50 pb-2`}
  }
  button{
    ${tw`bg-transparent border-0 fill-current cursor-pointer `}
  }
  .user-info{
    ${tw`flex flex-col items-start flex-grow`}
    .username{
      ${tw`font-serif text-lg leading-5 tracking-wide`}
    }
    .datetime{
      ${tw`text-sm leading-4 mb-2 tracking-wide`}
    }
  }
`
const RepliesArea = styled.div`
  ${tw`flex flex-col rounded-md pt-2 bg-primary-1 break-inside[avoid] w-full space-y-4 px-4 bg-white`}

`
const ReplyArea = styled.div`
  ${tw`flex flex-row space-x-2 py-2 items-start justify-start border-0 border-b border-primary-2 border-solid`}
  &:last-child{
    ${tw`border-b-0`}
  }
  .icon{
    ${tw`w-10 h-10 text-secondary/60 hover:text-secondary`}
    svg{
      ${tw`w-5 h-5`}
    }
  }
  button{
    ${tw`bg-transparent border-0 fill-current cursor-pointer `}
  }
  .user-info{
    ${tw`flex flex-col items-start flex-grow`}
    .username{
      ${tw`font-serif text-base leading-5  tracking-wide`}
    }
    .datetime{
      ${tw`text-sm leading-4 mb-2 tracking-wide`}
    }
  }
`


export default CommentItem