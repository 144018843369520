import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import Layout from "../../components/layout/Layout";
import ObituaryUserInfo from "../../components/organisms/obituary/ObituaryUserInfo";

type Props = {};

const Obituary = (props: Props) => {
  const {t} = useTranslation()
  const params = useParams()
  return (
    <Layout>
      <HeaderInternal>
        <img
          src="https://res.cloudinary.com/dkyl4jbxx/image/upload/v1644325479/mein/assets/header_obituary.png"
          alt="header"
        />
        <div className="area">
          <p className="title">
            {t(`obituary.${params.id === "new" ? "create" : "edit"}.obituraryTitle`)}
          </p>
          <p className="info">
            {t(`obituary.${params.id === "new" ? "create" : "edit"}.obiturarySubTitle`)}
          </p>
        </div>
      </HeaderInternal>
      <ObituaryUserInfo />
    </Layout>
  );
};

const HeaderInternal = styled.div`
  /* background-image: url("https://res.cloudinary.com/dkyl4jbxx/image/upload/v1643917716/mein/assets/flowers.png"); */
  ${tw`bg-right-top flex flex-col space-y-2 md:space-y-0 md:flex-row justify-around items-end mb-6`}
  img {
    ${tw`h-auto w-1/2 mx-auto md:w-1/6`}
  }
  .area {
    ${tw`w-full md:w-9/12 space-y-2`}
    .title {
      ${tw`text-4xl font-serif text-primary-4`}
    }
    span {
      ${tw`text-4xl font-serif text-black`}
    }
  }
  .info {
    ${tw`text-lg font-sans tracking-wider`}
  }
`;

export default Obituary;
