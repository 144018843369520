import { Auth0Provider } from "@auth0/auth0-react";
import React, { PropsWithChildren } from "react";
import configDevJson from "../auth_config_dev.json";
// import configProdJson from "../auth_config_prod.json";

export const Auth0ProviderWithHistory = ({
  children,
}: PropsWithChildren<any>): JSX.Element | null => {

  const domain: string | undefined = process.env.REACT_APP_AUTH_DOMAIN ? process.env.REACT_APP_AUTH_DOMAIN : configDevJson.domain;
  const clientId: string | undefined = process.env.REACT_APP_AUTH_CLIENT_ID ? process.env.REACT_APP_AUTH_CLIENT_ID: configDevJson.clientId;
  const audience: string | undefined = process.env.REACT_APP_AUTH_AUDIENCE ? process.env.REACT_APP_AUTH_AUDIENCE: configDevJson.audience;

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      audience={audience}
      redirectUri={window.location.origin}
    >
      {children}
    </Auth0Provider>
  );
};
