import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { ColorProps, IObituaryTributeType, SizeProps } from '../../../resources';
import { resizeImage, getTributeIcon } from '../../../lib/images';
import NotificationMarker from '../../atoms/common/NotificationMarker';

import { useRecoilValue, useSetRecoilState } from "recoil"
import { TObituaryTribute } from "../../../resources"
import { obituaryAtom, tributesModalAtom } from "../../../state/atoms"
import { t } from 'i18next';

type Props = {}

const ObituaryTributesArea = (props: Props) => {
  const obituary = useRecoilValue(obituaryAtom)
  if(obituary && obituary.tributes) {
    return (
      <Container>
           <p className="title">{getTitle(obituary?.tributes.length)}</p>
       <GridContainer>
          <RenderGroup type="STAR" />
           <RenderGroup type="BALLOON" />
           <RenderGroup type="DOVE" />
           <RenderGroup type="CROWN" />
           <RenderGroup type="FLOWER" />
           <RenderGroup type="CANDLE" />
         </GridContainer>  
      </Container>
    )
  }
  return <>{}</>
}

const getTitle = (count: number) => {
  if(count === 0){
    return t('obituary.tributes.notribute')
  }else if(count === 1){
    return t('obituary.tributes.onetribute')
  }else{
    return t('obituary.tributes.moretributes').replace("{{count}}", count.toString())
  }
}


const RenderGroup = (props:any) =>{
  let type:IObituaryTributeType = props.type
  const setTribute = useSetRecoilState(tributesModalAtom)
  const obituary = useRecoilValue(obituaryAtom)
  const openList = () => {
    setTribute({
      open: true,
      type: props.type})
  }
  let items = obituary.tributes.filter((tribute:TObituaryTribute) => tribute.tributeType === type)
  if(items.length > 0) {
    return <GridItem onClick={()=>{
      openList()
    }}>
      <img src={resizeImage(getTributeIcon(type),120,120)} className=" h-32 w-32" alt={type}/>
      <NotificationMarker number={items.length} visual={ColorProps.PRIMARY} size={SizeProps.MEDIUM} />

    </GridItem>
  }
  return <GridItem>
  <img src={resizeImage(getTributeIcon(type),120,120)} className=" h-32 w-32" alt={type}/>

  <NotificationMarker number={0} visual={ColorProps.SECONDARY} size={SizeProps.MEDIUM} />

</GridItem>
}

const Container = styled.div`
  ${tw`bg-white p-2 w-full my-2`}
  .title{
    ${tw`text-2xl font-serif py-2 text-center`}
  }
`

const GridContainer = styled.div`
  ${tw`flex flex-row gap-1 md:gap-4 lg:gap-8 pt-4 items-center justify-center`}
`

const GridItem = styled.div`
  ${tw`relative flex flex-row `}
  
  img{
    ${tw`h-12 w-12 md:w-16 md:h-16 relative`}
  }
`
// const List = styled.ul`
//   ${tw`inline-flex`}
  
//   .item{
//     ${tw`text-sm font-medium text-primary-4`}
//     &::after{
//       ${tw`px-2`}
//       content:" - "
//     }
//     &:last-child{
//       &::after{
//         ${tw`px-0`}
//         content:""
//       }
//     }
//   }
// `

export default ObituaryTributesArea