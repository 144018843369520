import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useRecoilValue } from 'recoil';
import { obituaryAtom } from '../../../state/atoms';
import { TObituaryComment } from '../../../resources';
import CommentItem from './CommentItem';
import { t } from 'i18next';

type Props = {}

const ObituaryCommentsArea = (props: Props) => {
  const obituary = useRecoilValue(obituaryAtom)
  // const { obituary } = useSelector((state: RootStateOrAny) => state);
  if(obituary.comments) {
    return (
      <Container>
        <p className="title">{getTitle(obituary.comments.length)}</p>
        <GridContainer>
          {obituary.comments.map((comment: TObituaryComment, index:number)=><CommentItem {...comment} key={index}/>)}
        </GridContainer>
      </Container>
    )
  }
  return <></>
}

const getTitle = (count: number) => {
  if(count === 0){
    return t('obituary.comments.nocomment')
  }else if(count === 1){
    return t('obituary.comments.onecomment')
  }else{
    return t('obituary.comments.morecomments').replace("{{count}}", count.toString())
  }
}
const Container = styled.div`
  ${tw`bg-white p-2 w-full my-2`}
  .title{
    ${tw`text-2xl font-serif py-2 text-center`}
  }
`

const GridContainer = styled.div`
  ${tw`column-count[1] md:column-count[2] w-full gap-2 `}
`

// const GridItem = styled.div`
//   ${tw`bg-primary-1 rounded-2xl p-2 flex flex-row space-x-4 items-center`}
//   img{
//     ${tw`h-12 w-12 `}
//   }
// `
// const List = styled.ul`
//   ${tw`inline-flex`}
  
//   .item{
//     ${tw`text-sm font-medium text-primary-4`}
//     &::after{
//       ${tw`px-2`}
//       content:" - "
//     }
//     &:last-child{
//       &::after{
//         ${tw`px-0`}
//         content:""
//       }
//     }
//   }
// `
export default ObituaryCommentsArea