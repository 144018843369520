import React from "react";
import ReactDOM from "react-dom";
// import { Provider } from "react-redux";
import App from "./App";
// import store from "./store/store";
import { Auth0ProviderWithHistory } from "./auth0/Auth0ProviderWithHistory";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import DebugObserver from "./components/debug/DebugObserver";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <Auth0ProviderWithHistory>
      <RecoilRoot>
        <DebugObserver />
        <App />
      </RecoilRoot>
    </Auth0ProviderWithHistory>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
