import React from 'react'
import { SiFacebook, SiInstagram, SiTwitter, SiTiktok, SiYoutube } from 'react-icons/si';
import styled from 'styled-components'
import tw from 'twin.macro'
import benuLogo from "./../../../assets/svgs/benuLogo.svg";

type Props = {}

const Footer = (props: Props) => {
  return (
    <BlockContainer>
      <Container>
        <LogoArea>
        <a href='https://www.benu.at/' target="_blank" rel="noreferrer">
        <img src={benuLogo} alt="" />
        </a>
        </LogoArea>
        <IconsArea>
          <a href='https://www.facebook.com/benubestattungen/' target="_blank" rel="noreferrer">
            <SiFacebook />
          </a>
          <a href='https://www.instagram.com/benu_bestattung/' target="_blank" rel="noreferrer">
            <SiInstagram />
          </a>
          <a href='https://twitter.com/benu_bestattung' target="_blank" rel="noreferrer">
            <SiTwitter />
          </a>
          <a href='https://www.tiktok.com/@benu_bestattung' target="_blank" rel="noreferrer">
            <SiTiktok />
          </a>
          <a href='https://www.youtube.com/channel/UCFpE_1W49Km7Ty53ovheswg' target="_blank" rel="noreferrer">
            <SiYoutube />
          </a>
        </IconsArea>
        <InfoArea>© 2022 Benu GmbH. Alle Rechte vorbehalten.</InfoArea>
      </Container>
    </BlockContainer>
  )
}

const Container = styled.div`
  ${tw`w-full xl:container mx-auto mt-10 mb-2 md:my-2 grid grid-cols-1 md:grid-cols-2 gap-y-6 md:gap-6 px-4`}
  div{
    ${tw`flex my-auto`}
}
`

const LogoArea = styled.div`
  ${tw`w-full md:w-auto  flex flex-row items-center justify-center md:justify-start`}
  img{
    ${tw`h-16 md:h-12`}
  }
`

const IconsArea = styled.div`
  ${tw`w-full md:w-auto flex flex-row space-x-5 justify-center md:justify-end`}
  a{
    ${tw`text-primary-4 hover:text-black cursor-pointer transition-all duration-200` }
  }
  svg{
    ${tw`h-6 w-6`}
  }
`
const InfoArea = styled.div`
  ${tw`w-full md:w-auto col-span-1 md:col-span-2 py-6 border-solid border-0 md:border-primary-3 border-t order-3 flex items-center justify-center`}
`

const BlockContainer = styled.div`
  ${tw`flex flex-col md:flex-row bg-primary-1 py-8 `}
`


export default Footer