import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { deAT, enUS } from 'date-fns/locale'
import i18next from 'i18next';
export const formatDateToString = (date:string) => {
  let temp = date.split("T")[0].split("-");
  return `${temp[2]}.${temp[1]}.${temp[0]}`
}

const getCurrentLanguage = () => {
  if(i18next.language === "de" || i18next.language === "de-AT") {
    return deAT
  }else{
    return enUS
  }
}

export const formatTimeToNow = (date:string) => {
 return (formatDistanceToNow(new Date(date), 
  {locale: getCurrentLanguage()}
  )) 
}