import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import tw from "twin.macro";

const GooglePlaceField = (props) => {
  let {name, label, searchType, setValue, alias, error, value, dataCi} = props
  let autoComplete;
   
  const [query, setQuery] = useState(value ? value : "");
  const autoCompleteRef = useRef(null);

  const loadScript = (url, callback) => {
    if (!document.getElementById("apiGooglePlaces")) {
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.id = "apiGooglePlaces";

      if (script.readyState) {
        script.onreadystatechange = function () {
          if (
            script.readyState === "loaded" ||
            script.readyState === "complete"
          ) {
            script.onreadystatechange = null;
            callback();
          }
        };
      } else {
        script.onload = () => callback();
      }

      script.src = url;
      document.getElementsByTagName("head")[0].appendChild(script);
    } else {
      callback();
    }
  };

  const handleScriptLoad = (updateQuery, autoCompleteRef) => {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { types: [searchType === "city" ? "(cities)" : "establishment"], componentRestrictions: { country: "at" } }
    );
    autoComplete.setFields([
      "address_component",
      "formatted_address",
      "geometry",
      "name",
    ]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery)
    );
  };

  const handlePlaceSelect = async (updateQuery) => {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.name;
    updateQuery(query);
    if(alias){
      setValue(alias, addressObject.name)
    }else{
      let street_number = addressObject.address_components.filter((item) => item.types.includes("street_number"))
      let route = addressObject.address_components.filter((item) => item.types.includes("route"))
      let locality = addressObject.address_components.filter((item) => item.types.includes("locality"))
      let postal_code = addressObject.address_components.filter((item) => item.types.includes("postal_code"))
      let values = {
        route:  route ? route[0].short_name : '',
        street_number: street_number ? street_number[0].short_name : '',
        locality:  locality ? locality[0].short_name : '',
        postal_code:  postal_code ? postal_code[0].short_name : '',
        lat: addressObject.geometry.location.lat(),
        lng:  addressObject.geometry.location.lng(),
        name: addressObject.name
      };
      setValue(values)
    }
  };

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyC1FnqfxEtPCI6h1wg5iJ9EdOqtJbv1wxY&libraries=places&language=de`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [hideLabel, setHideLabel] = useState(value ? true : false);

  return (
  <CustomInputArea error={error}>
      <CustomInput
        name={name}
        ref={autoCompleteRef}
        data-ci={`${dataCi}`}
        onChange={(e) => {
          setQuery(e.target.value);
          e.target.value.length === 0
            ? setHideLabel(false)
            : setHideLabel(true);
        }}
        onBlur={(e) => {
          e.preventDefault()
          e.target.value.length === 0
            ? setHideLabel(false)
            : setHideLabel(true);
        }}
        onFocus={(e) => {
          e.preventDefault()
          setHideLabel(true)
        }}
        onKeyPress={e => { e.which === 13 && e.preventDefault() }}
        placeholder=""
        value={query}
        hide={hideLabel}
      />
      <CustomLabel hide={hideLabel}>{label}</CustomLabel>
      {error && <ErrorArea>{error}</ErrorArea>}
    </CustomInputArea>
  );
};
const CustomInputArea = styled.div`
  ${tw`flex flex-col w-full relative bg-white border border-solid h-14 rounded-lg`}
  ${(props) => (props.error ? tw`border-secondary` : tw`border-primary-3`)}
`;
const CustomInput = styled.input`
  ${tw`bg-white h-full pt-5 px-3 outline-none  rounded-md font-sans tracking-wide relative border-0 text-base`}
  &[type="date"]::-webkit-calendar-picker-indicator, &[type="time"]::-webkit-calendar-picker-indicator {
    ${tw`opacity-25 pb-5`}
  }
  &[type="date"]::-webkit-datetime-edit,
  &[type="time"]::-webkit-datetime-edit {
    ${(props) => (props.hide ? tw`text-black` : tw`text-transparent`)};
  }
`;
const ErrorArea = styled.div`
  ${tw`text-xs absolute top-0 right-0 bg-secondary/70 text-white px-1 rounded-tr rounded-bl`}
`;
const CustomLabel = styled.label`
  ${tw`absolute font-sans left-3 transition-all duration-300 ease-in-out pointer-events-none`}
  ${(props) =>
    props.hide
      ? tw`top-0.5 text-primary-4 text-sm tracking-normal`
      : tw`top-4 text-black-40 text-base tracking-wide`}
`;

export default GooglePlaceField;
