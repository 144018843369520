import React from "react";
// import { useParams } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
// import ContentTitleBox from "../../molecules/common/ContentTitleBox";

import ObituaryUserInfoForm from "../../molecules/obituary/ObituaryUserInfoForm";

type Props = {};

const ObituaryUserInfo: React.FC<Props> = (props: Props) => {
  // const params = useParams()
  return (
    <>
      <InfoContainer>
        {/* <ContentTitleBox step={1} title={params.id !== "new" ? "Edit Obituary" : "Create Obituary" } /> */}
        <ObituaryUserInfoForm />
      </InfoContainer>

    </>
  );
};

const InfoContainer = styled.div`
  ${tw`bg-primary-1 w-full col-span-3 flex flex-grow flex-col p-3 space-y-2 hover:shadow`}
`;

export default ObituaryUserInfo;
