import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

type Props = {
  title: string
  subtitle: string
  id:string
  children?: React.ReactNode
}

const TitleComponent = ({title, subtitle, children, id}: Props) => {
  return (
    <TitleArea id={id}>
      <h1 className='title'>
        {title}
      </h1>
      <p className='subtitle'>
        {subtitle}
      </p>
      {children && children}
    </TitleArea>
  )
}

const TitleArea = styled.div`
  ${tw`flex flex-col space-y-8 w-full`}
  .title{
    ${tw`font-serif text-3xl tracking-wide`}
  }
  .subtitle{
    ${tw`font-sans text-lg tracking-wider`}
  }
`

export default TitleComponent