/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import * as Yup from "yup";
import { Form, Field, Formik } from "formik";
import CustomInputComponent from "../../atoms/form/CustomInputComponent";
import DropAreaUserImage from "../form/DropAreaUserImage";
import { useTranslation } from "react-i18next";
import ButtonComponent from "../../atoms/common/ButtonComponent";
import {
  ColorProps,
  IObituaryItem,
  SizeProps,
  TObituaryComment,
  TObituaryTribute,
  VisualProps,
} from "../../../resources";
import ModalContainer from "../../atoms/common/ModalContainer";
import ObituaryLocationEvent from "./ObituaryLocationEvent";
import ManageServiceLocation from "./ManageServiceLocation";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  newObituaryTemplate,
  useObituaryCreate,
} from "../../../state/hooks/obituary/obituary";
import axiosApi from "../../../api/axiosApi";
import GooglePlaceField from "../../atoms/form/GooglePlaceField";
// import CustomToogleComponent from "../../atoms/form/CustomToogleComponent";
import ObituaryCommentsArea from "./ObituaryCommentsArea";
import ObituaryTributesArea from "./ObituaryTributesArea";
import DropAreaGallery from "../form/DropAreaGallery";
import {
  createObituaryModalAtom,
  deleteObituaryModalAtom,
  obituaryAtom,
  userAtom,
} from "../../../state/atoms";
import CommentDelete from "./CommentDelete";
import TributesList from "./TributesList";
import BioEditor from "./BioEditor";
// import CreateObituaryModal from "./CreateObituaryModal";
import DeleteObituaryModal from "./DeleteObituaryModal";
import { FiLink, FiSave, FiTrash2 } from "react-icons/fi";
import { FaPlus } from "react-icons/fa";

type Props = {};
// interface CreateObituaryValues {
//   name: string;
//   birthDate: Date | string;
//   deathDate: Date | string;
//   location: string;
//   bio: string;
// }

const ObituaryUserInfoForm = (props: Props) => {
  let params = useParams();
  // const obituaries = useRecoilValue(obituariesAtom)
  const [obituary, setObituary] = useRecoilState(obituaryAtom);
  const user_info = useRecoilValue(userAtom);
  const [isLoaded, setIsLoaded] = useState(false);
  const setCreateObituary = useSetRecoilState(createObituaryModalAtom);
  const setDelObituary = useSetRecoilState(deleteObituaryModalAtom);
  const navigate = useNavigate();

  const { t } = useTranslation();
  const createNewObituary = useObituaryCreate();

  useEffect(() => {
    document.title = "Mein Benu - Traueranzeigen";
  });

  // const toogleOption = useToogleOptions()

  const FormSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, t("obituary.form.alerts.name.min"))
      .max(50, t("obituary.form.alerts.name.max"))
      .required(t("obituary.form.alerts.name.required")),
    birthDate: Yup.date()
      .required(t("obituary.form.alerts.birthDate.required"))
      .min(new Date("01/01/1900"), t("obituary.form.alerts.birthDate.min"))
      .max(new Date(), t("obituary.form.alerts.birthDate.max")),
    deathDate: Yup.date()
      .required(t("obituary.form.alerts.deathDate.required"))
      .min(new Date("01/01/1900"), t("obituary.form.alerts.deathDate.min"))
      .max(new Date(), t("obituary.form.alerts.deathDate.max")),
    location: Yup.string().required(
      t("obituary.form.alerts.location.required")
    ),
  });

  const loadObituaryData = async () => {
    const obituaryItem: IObituaryItem = await axiosApi
      .get(`/obituary/${params.id}`)
      .then((res) => {
        return res.data[0];
      });
    if (obituaryItem.bio === null) {
      obituaryItem.bio = "";
    }
    obituaryItem.birthDate = (obituaryItem.birthDate as string).split("T")[0];
    obituaryItem.deathDate = (obituaryItem.deathDate as string).split("T")[0];
    if (obituaryItem.gallery === null) {
      obituaryItem.gallery = [];
    }
    if (obituaryItem.receptionLocation === null) {
      obituaryItem.receptionLocation = {
        type: "reception",
      };
    }
    if (obituaryItem.serviceLocation === null) {
      obituaryItem.serviceLocation = {
        type: "service",
      };
    }
    setObituary(obituaryItem);
    setIsLoaded(true);
    const tributes: TObituaryTribute[] = await axiosApi
      .get(`/obituary/${obituaryItem.id}/tributes`)
      .then((res) => res.data);
    setObituary((oldState) => {
      return {
        ...oldState,
        tributes,
      };
    });
    const comments: TObituaryComment[] = await axiosApi
      .get(`/obituary/${obituaryItem.id}/comments`)
      .then((res) => res.data);
    setObituary((oldState) => {
      return {
        ...oldState,
        comments,
      };
    });
  };

  const sendObituaryData = async (data: any) => {
    await axiosApi
      .post(`/obituary`, data)
      .then((res) => {
        if (!obituary.slug) {
          setCreateObituary({ open: true });
        }
        return res;
      })
      .then((res) => {
        res.data.birthDate = (res.data.birthDate as String).split("T")[0];
        res.data.deathDate = (res.data.deathDate as String).split("T")[0];
        setObituary((oldState) => {
          return {
            ...res.data,
            comments: oldState.comments,
            tributes: oldState.tributes,
          };
        });
      })
      .then((res) => navigate("/dashboard"));
  };
  useEffect(() => {
    setIsLoaded(false);
    if (params.id === "new") {
      createNewObituary(newObituaryTemplate);
      setIsLoaded(true);
    } else if (params.id) {
      loadObituaryData();
    }
  }, [params.id, user_info]);

  if (isLoaded) {
    return (
      <>
        {(obituary.modalService || obituary.modalReception) && (
          <ModalContainer
            closeModal={() => {
              // dispatch(closeModal());
            }}
          >
            <ObituaryLocationEvent />
          </ModalContainer>
        )}
        <CommentDelete />
        <TributesList />
        <DeleteObituaryModal />

        <Formik
          initialValues={obituary}
          validationSchema={FormSchema}
          onSubmit={(values, actions) => {
            sendObituaryData({
              ...obituary,
              name: values.name,
              birthDate: `${values.birthDate}T03:00`,
              deathDate: `${values.deathDate}T03:00`,
              location: values.location,
            });
          }}
        >
          {({ errors, touched, setFieldValue, isValidating }) => (
            <Form>
              {params.id !== "new" && (
                <ViewObituaryArea
                  onClick={() =>
                    window.open(
                      `${process.env.REACT_APP_MAIN_SITE}/traueranzeigen/${obituary.slug}`,
                      "_blank"
                    )
                  }
                >
                  <FiLink size={12} />
                  <p>{t("obituary.form.openObituaryLabel")}</p>
                </ViewObituaryArea>
              )}
              <FormContainer>
                <FormBlock key={0}>
                  <FieldsArea>
                    <Col2>
                      <DropAreaUserImage key="user-Image" />
                    </Col2>
                    <Col2>
                      <Field
                        key={1}
                        component={CustomInputComponent}
                        type="text"
                        name="name"
                        label={t("obituary.form.namePlaceholder")}
                      />
                    </Col2>
                    <Field
                      key={2}
                      component={CustomInputComponent}
                      type="date"
                      name="birthDate"
                      label={t("obituary.form.birthDatePlaceholder")}
                    />
                    <Field
                      component={CustomInputComponent}
                      type="date"
                      name="deathDate"
                      label={t("obituary.form.deatheDatePlaceholder")}
                    />
                    <Col2>
                      <Field
                        key={10}
                        type="text"
                        name="location"
                        hidden={true}
                      />
                      <GooglePlaceField
                        name="locationPlace"
                        dataCi="locationPlace"
                        label={t("obituary.form.locationPlaceholder")}
                        searchType="city"
                        error={errors.location}
                        setValue={setFieldValue}
                        alias="location"
                        value={obituary.location}
                      />
                    </Col2>
                    <Col2>
                      <ManageServiceLocation location="service" />
                    </Col2>
                    <Col2>
                      <ManageServiceLocation location="reception" />
                    </Col2>
                  </FieldsArea>
                  <BioArea>
                    <BioEditor label={t("obituary.form.bioPlaceholder")} />
                  </BioArea>
                </FormBlock>
                <DropAreaGallery key={1} />
                {/* <TooglesArea key={2}>
                  <CustomToogleComponent
                    key={5}
                    dataCi={"tributes"}
                    value={obituary?.enableTributes ? obituary?.enableTributes : false}
                    setValue={() => toogleOption("tributes")}
                    label={t("obituary.form.enableTributesPlaceholder")}
                    labelFalse={t("obituary.form.toogleDisabled")}
                    labelTrue={t("obituary.form.toogleEnabled")}
                  />
                  <CustomToogleComponent
                    key={6}
                    dataCi={"comments"}
                    value={obituary?.enableComments ? obituary?.enableComments : false}
                    setValue={() => toogleOption("comments")}
                    label={t("obituary.form.enableCommentsPlaceholder")}
                    labelFalse={t("obituary.form.toogleDisabled")}
                    labelTrue={t("obituary.form.toogleEnabled")}
                  />
                  <CustomToogleComponent
                    key={7}
                    dataCi={"replies"}
                    value={obituary?.enableReplies ? obituary?.enableReplies : false}
                    setValue={() => toogleOption("replies")}
                    label={t("obituary.form.enableRepliesPlaceholder")}
                    labelFalse={t("obituary.form.toogleDisabled")}
                    labelTrue={t("obituary.form.toogleEnabled")}
                  />
                </TooglesArea> */}
                {params.id !== "new" && (
                  <>
                    <ObituaryTributesArea key={"tributes-area"} />
                    <ObituaryCommentsArea key={"comments-area"} />
                  </>
                )}
              </FormContainer>
              <FooterArea>
                {obituary.slug && (
                  <div>
                    <ButtonComponent
                      visual={VisualProps.SOLID}
                      size={SizeProps.MEDIUM}
                      color={ColorProps.SECONDARY}
                      onClick={() => {
                        setDelObituary({ open: true });
                      }}
                      dataCi={"delete-obituary"}
                    >
                      <>
                        <FiTrash2 size={16} />
                        {t("obituary.form.deleteObituaryButton")}
                      </>
                    </ButtonComponent>
                  </div>
                )}
                <ForceRight>
                  <div>
                    <ButtonComponent
                      visual={VisualProps.SOLID}
                      size={SizeProps.MEDIUM}
                      color={ColorProps.PRIMARY}
                      isSubmit={true}
                      dataCi={"submit-obituary"}
                    >
                      {!obituary.slug ? (
                        <>
                          <FaPlus size={16} />
                          {t("obituary.create.submitButton")}
                        </>
                      ) : (
                        <>
                          <FiSave size={16} />
                          {t("obituary.edit.submitButton")}
                        </>
                      )}
                    </ButtonComponent>
                  </div>
                </ForceRight>
              </FooterArea>
            </Form>
          )}
        </Formik>
      </>
    );
  } else {
    return <></>;
  }
};

const FormContainer = styled.div`
  ${tw`bg-gradient-to-b from-primary-2  via-white to-white w-full flex flex-col justify-between`}
`;
const FieldsArea = styled.div`
  ${tw`w-full md:w-5/12 p-3 grid grid-cols-2 gap-2 justify-between`}
`;
const BioArea = styled.div`
  ${tw`flex w-full md:w-7/12 flex-col p-3 bg-primary-2 justify-between flex-grow m-0 mb-4 md:m-3`}
`;
const Col2 = styled.div`
  ${tw`col-span-2`}
`;
const FormBlock = styled.div`
  ${tw`flex flex-col md:flex-row w-full `}
`;
// const TooglesArea = styled.div`
//   ${tw`flex flex-row w-full p-3 bg-white space-x-6`}
// `;
const FooterArea = styled.div`
  ${tw`flex flex-row w-full p-1 md:p-3 justify-between items-center pb-0 space-x-2`}
`;
const ForceRight = styled.div`
  ${tw`ml-auto`}
`;
const ViewObituaryArea = styled.div`
  ${tw`flex flex-row justify-end items-center pb-2`}
  p {
    ${tw`text-primary-4 text-sm hover:underline font-bold cursor-pointer`}
  }
  svg {
    ${tw`mr-2`}
  }
`;

export default ObituaryUserInfoForm;
