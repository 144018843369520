import { t } from "i18next";
import React from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import tw from "twin.macro";
import { TObituaryLocationTypes, SizeProps, TObituaryLocationProps, VisualProps, ColorProps } from "../../../resources";
import { obituaryAtom } from "../../../state/atoms";
import { useCleanLocation, useOpenLocation } from "../../../state/hooks/obituary/obituary";
import ButtonComponent from "../../atoms/common/ButtonComponent";
import plus from "./../../../assets/svgs/icons/plus.svg";


interface ManageServiceLocationProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  location: TObituaryLocationTypes
}

const ManageServiceLocation: React.FC<ManageServiceLocationProps> = ({location,  ...props} : ManageServiceLocationProps) => {
  const [obituary] = useRecoilState(obituaryAtom)
  const openLocation = useOpenLocation()
  const deleteLocation = useCleanLocation()
  // const { obituary } = useSelector((state: RootStateOrAny) => state);
  // const dispatch = useDispatch();
  const openModal = (e:any) => {
    e.preventDefault();
    openLocation(location)
  }
  const cleanLocation = (e:any) => {
    e.preventDefault();
    deleteLocation(location)
  }
  let info:TObituaryLocationProps = obituary[location === "reception" ? "receptionLocation" : "serviceLocation"] as TObituaryLocationProps
  if(!info?.location){
    return <ButtonContainer type="button" data-ci={`${location}-add`} onClick={(e)=>openModal(e)}>
          <div className="icon">
        <img src={plus} alt="" />
      </div><p>{t(`obituary.form.${location}.addButton`)}</p>
  </ButtonContainer>
  } else {
    return <Container>
       <p className="title">{t(`obituary.form.${location}.title`)}</p>
       {info.location.route && <><img src={`https://cdn.benu.at/map?size=400x200&markers=icon:https://res.cloudinary.com/dkyl4jbxx/image/upload/w_48,f_auto/v1610720622/extensions/googlemaps/default.png%7C${info.location?.lat ? info.location.lat : "" },${info.location?.lng ? info.location.lng : ""}`} alt="test"/>
       <p><span className="date">{t(`obituary.form.${location}.addressPlaceholder`)}: </span>{`${info.location.route}, ${info.location.street_number} - 
       ${info.location.locality}, ${info.location.postal_code}`}</p></>}
       <p><span className="date">{t(`obituary.form.${location}.datePlaceholder`)}: </span>{info.date} - {info.time}</p>       
       {info.mainInfo && <p><span className="date">{t(`obituary.form.${location}.mainInfoPlaceholder`)}: </span>{info.mainInfo}</p>}
       {info.alternativeInfo && <p><span className="date">{t(`obituary.form.${location}.additionalInfoPlaceholder`)}: </span>{info.alternativeInfo}</p>}
       <div className="buttons-area">
       <ButtonComponent 
           visual={VisualProps.SOLID}
           size={SizeProps.SMALL}
           color={ColorProps.PRIMARY}
           onClick={(e) => openModal(e)}
           dataCi={`${location}-edit`}
         >{t(`obituary.form.${location}.editButton`)}</ButtonComponent>
         <ButtonComponent 
           visual={VisualProps.SOLID}
           size={SizeProps.SMALL}
           color={ColorProps.PRIMARY}
           onClick={(e) => cleanLocation(e)}
           dataCi={`${location}-delete`}
         >{t(`obituary.form.${location}.deleteButton`)}</ButtonComponent>
       </div>
    </Container>
  }
  
};


const ButtonContainer = styled.button`
 ${tw` w-full relative cursor-pointer border-0 bg-primary-1 flex flex-row p-4 justify-start items-center space-x-4 text-base hover:bg-primary-2 hover:shadow-lg transition-all ease-in-out duration-500`}
 .icon{
  ${tw`h-12 w-12 bg-secondary rounded-full flex justify-center items-center`}
 }

`



export default ManageServiceLocation;

const Container = styled.div`
  ${tw` w-full relative p-3  border-0 bg-primary-1 flex flex-col justify-center items-start `}
  .title{
    ${tw`font-serif text-lg mb-1 `}
  }
  .buttons-area{
    ${tw`flex flex-row space-x-2 justify-end items-center w-full`}
  }
  img{
    ${tw`w-full h-24 object-cover border-solid border-primary-3/60 border mb-3 rounded-md`}
  }
  p{
    ${tw`inline text-base text-left pb-1`}
    span{
      ${tw`font-semibold text-primary-4`}
    }
  }
`;
